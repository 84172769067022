import dayjs, { Dayjs } from 'dayjs';
import { TCarbonSourceDataType, TLocationDataType } from 'interfaces/mapInterface';
import { StateCreator, create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type CarbonBudgetDataType = {
  startDate: Dayjs;
  endDate: Dayjs;
  location: TLocationDataType;
  tense: string;
  dataType: TCarbonSourceDataType[];
};

type MapState = {
  selectDataCondition: CarbonBudgetDataType;
  activeYearMonth: Dayjs;
};

type MapAction = {
  setDataCondition: (dataCondition: CarbonBudgetDataType) => void;
  updateTimelapseMonth: (value: number) => void;
};

const store: StateCreator<MapState & MapAction, [], [['zustand/immer', never]]> = immer((set) => ({
  selectDataCondition: {
    startDate: dayjs('2022-12'),
    endDate: dayjs('2022-12'),
    location: {
      ctp_cd: 11000000,
      sig_cd: undefined,
      emd_cd: undefined,
    },
    tense: 'present',
    dataType: ['ei', 'f', 'mic', 'os', 'rt', 'nep'],
  },
  activeYearMonth: dayjs('2022-12'),
  setDataCondition: (dataCondition) =>
    set((state) => {
      const { startDate } = dataCondition;

      state.selectDataCondition = dataCondition;
      state.activeYearMonth = startDate;
    }),
  updateTimelapseMonth: (value) =>
    set((state) => {
      if (!state.selectDataCondition || !state.activeYearMonth) return;
      state.activeYearMonth = state.selectDataCondition.startDate.add(value, 'month');
    }),
}));

const useMapStore = process.env.REACT_APP_SERVER_MODE === 'development' ? create(devtools(store)) : create(store);

export default useMapStore;
