import { TGetDistrictsDataResponse } from 'interfaces/districtInterface';
import { commonAxios } from './commonApi';

export const getDistrictsData = async (ctp_cd: number): Promise<TGetDistrictsDataResponse[] | undefined> => {
  try {
    const res = await commonAxios.get(`/district?ctp_cd=${ctp_cd}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
