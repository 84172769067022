import ConfirmButton from '@components/common/ConfirmButton';
import CarbonCategorySelector from '@components/common/selector/carbonCategorySelector/CarbonCategorySelector';
import TenseSelector from '@components/common/selector/TenseSelector';
import LocationSelector from '@components/common/selector/locationSelector/LocationSelector';
import { newColors } from '@styles/theme';
import styled from 'styled-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useDashboardStore, { TCarbonRanking } from 'stores/dashboardStore';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';
import { convertArrayToLocation, convertLocationToArray } from '@utils/convertLocationToArray';
import { useTranslation } from 'react-i18next';

export type TCarbonRankingInput = {
  location: string[];
  tense: string;
  carbonCategory: TCarbonSourceDataType[];
};

const CarbonRankingSearchBar = () => {
  const { carbonRanking, setCarbonRanking } = useDashboardStore();
  const { t } = useTranslation();

  const defaultValues: TCarbonRankingInput = {
    location: convertLocationToArray(carbonRanking.location),
    tense: carbonRanking.tense,
    carbonCategory: carbonRanking.dataType,
  };

  const methods = useForm<TCarbonRankingInput>({ defaultValues, mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handleFormSubmit: SubmitHandler<TCarbonRankingInput> = (formData) => {
    const { location, carbonCategory, tense } = formData;
    const dataCondition: TCarbonRanking = {
      location: convertArrayToLocation(location),
      tense,
      dataType: carbonCategory,
    };

    setCarbonRanking(dataCondition);
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <SelectorContainer>
          <LocationSelector
            selectorId="ranking-location-selector"
            inputName="location"
            isThirdItem={false}
            backgroundColor={newColors.dark2}
          />
          <TenseSelector selectorId="ranking-tense-selector" inputName="tense" backgroundColor={newColors.dark2} />
          <CarbonCategorySelector
            selectorId="ranking-carbon-category-selector"
            inputName="carbonCategory"
            backgroundColor={newColors.dark2}
          />
        </SelectorContainer>
        <ConfirmButton text={t('common.search')} width="150px" disabled={!isValid} />
      </Form>
    </FormProvider>
  );
};

export default CarbonRankingSearchBar;

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 2px solid ${({ theme }) => theme.newColors.dark3};
  border-radius: 8px;

  ${({ theme }) =>
    theme.media.tablet(`
      flex-direction: column;
      button {
        margin: 30px auto 0px;
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      flex-direction: column;
      button {
        margin: 30px auto 0px;
      }
  `)}
`;

const SelectorContainer = styled.div`
  display: grid;
  grid-template-columns: 420px 274px 274px;
  gap: 20px;

  ${({ theme }) =>
    theme.media.tablet(`
      grid-template-columns: 2fr 1fr 1fr;
      & > div:nth-child(1) {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
      }

      & > div:nth-child(2) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
      
      & > div:nth-child(3) {
        grid-column: 2 / 4;
        grid-row: 2 / 3;
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      grid-template-columns: 1fr 1fr 1fr;
      & > div:nth-child(1) {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
      }

      & > div:nth-child(2) {
        grid-column: 1 / 4;
        grid-row: 2 / 3;
      }
      
      & > div:nth-child(3) {
        grid-column: 1 / 4;
        grid-row: 3 / 4;
      }
  `)}
`;
