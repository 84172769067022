import { IMemberInfo } from '@components/team/MemberItem';
import { OrganizationsType } from '@components/team/OrganizationItem';
import s from '@utils/i18nextScan';

export const profile: { id: number; title: string; member: IMemberInfo[] }[] = [
  {
    id: 1,
    title: s('profile.01-title'),
    member: [
      {
        image: '정수종.jpg',
        info: [s('profile.01-member01-info01'), s('profile.01-member01-name'), s('profile.01-member01-description')],
      },
      {
        image: '곽경환.jpg',
        info: [s('common.participant'), s('profile.01-member02-name'), s('profile.01-member02-description')],
      },
      {
        image: '박두선.jpg',
        info: [s('common.participant'), s('profile.01-member03-name'), s('profile.01-member03-description')],
      },
      {
        image: '손석우.jpg',
        info: [s('common.participant'), s('profile.01-member04-name'), s('profile.01-member04-description')],
      },
      {
        image: '국종성.jpg',
        info: [s('common.participant'), s('profile.01-member05-name'), s('profile.01-member05-description')],
      },
      {
        image: '이봉재.jpg',
        info: [s('common.participant'), s('profile.01-member06-name'), s('profile.01-member06-description')],
      },
      {
        image: '김민식.jpg',
        info: [s('common.participant'), s('profile.01-member07-name'), s('profile.01-member07-description')],
      },
    ],
  },
  {
    id: 2,
    title: s('profile.02-title'),
    member: [
      {
        image: '장동영.jpg',
        info: [s('common.section-PI'), s('profile.02-member01-name'), s('profile.02-member01-description')],
      },
      {
        image: '손효종.jpg',
        info: [s('common.participant'), s('profile.02-member02-name'), s('profile.02-member02-description')],
      },
      {
        image: '권형안.jpg',
        info: [s('common.participant'), s('profile.02-member03-name'), s('profile.02-member03-description')],
      },
      {
        image: '전원배.jpg',
        info: [s('common.participant'), s('profile.02-member04-name'), s('profile.02-member04-description')],
      },
      {
        image: '최우석.jpg',
        info: [s('common.participant'), s('profile.02-member05-name'), s('profile.02-member05-description')],
      },
      {
        image: '김화진.jpg',
        info: [s('common.participant'), s('profile.02-member06-name'), s('profile.02-member06-description')],
      },
    ],
  },
  {
    id: 3,
    title: s('profile.03-title'),
    member: [
      {
        image: '우정헌.jpg',
        info: [s('common.section-PI'), s('profile.03-member01-name'), s('profile.03-member01-description')],
      },
      {
        image: '오혜연.jpg',
        info: [s('common.participant'), s('profile.03-member02-name'), s('profile.03-member02-description')],
      },
      {
        image: 'xing_jia.jpg',
        info: [s('common.participant'), s('profile.03-member03-name'), s('profile.03-member03-description')],
      },
      {
        image: '백복행.jpg',
        info: [s('common.participant'), s('profile.03-member04-name'), s('profile.03-member04-description')],
      },
    ],
  },
  {
    id: 4,
    title: s('profile.04-title'),
    member: [
      {
        image: '유가영.jpg',
        info: [s('common.section-PI'), s('profile.04-member01-name'), s('profile.04-member01-description')],
      },
      {
        image: '조재일.jpg',
        info: [s('common.participant'), s('profile.04-member02-name'), s('profile.04-member02-description')],
      },
      {
        image: '임정호.jpg',
        info: [s('common.participant'), s('profile.04-member03-name'), s('profile.04-member03-description')],
      },
      {
        image: '김현석.jpg',
        info: [s('common.participant'), s('profile.04-member04-name'), s('profile.04-member04-description')],
      },
      {
        image: '오능환.jpg',
        info: [s('common.participant'), s('profile.04-member05-name'), s('profile.04-member05-description')],
      },
      {
        image: '이양원.jpg',
        info: [s('common.participant'), s('profile.04-member06-name'), s('profile.04-member06-description')],
      },
      {
        image: '강호정.jpg',
        info: [s('common.participant'), s('profile.04-member07-name'), s('profile.04-member07-description')],
      },
      {
        image: '박창의.jpg',
        info: [s('common.participant'), s('profile.04-member08-name'), s('profile.04-member08-description')],
      },
      {
        image: '박태원.jpg',
        info: [s('common.participant'), s('profile.04-member09-name'), s('profile.04-member09-description')],
      },
    ],
  },
  {
    id: 5,
    title: s('profile.05-title'),
    member: [
      {
        image: '함유근.jpg',
        info: [s('common.section-PI'), s('profile.05-member01-name'), s('profile.05-member01-description')],
      },
      {
        image: '김은솔.jpg',
        info: [s('common.participant'), s('profile.05-member02-name'), s('profile.05-member02-description')],
      },
      {
        image: '김진수.jpg',
        info: [s('common.participant'), s('profile.05-member03-name'), s('profile.05-member03-description')],
      },
    ],
  },
  {
    id: 6,
    title: s('profile.06-title'),
    member: [
      {
        image: '이승민.jpg',
        info: [s('common.section-PI'), s('profile.06-member01-name'), s('profile.06-member01-description')],
      },
    ],
  },
];

export const organizationsData: OrganizationsType[] = [
  {
    title: s('team.organizationsData-01-title'),
    description: s('team.organizationsData-01-description'),
    img: '/images/team/university',
  },
  {
    title: s('team.organizationsData-02-title'),
    description: s('team.organizationsData-02-description'),
    img: '/images/team/domestic',
  },
  {
    title: s('team.organizationsData-03-title'),
    description: s('team.organizationsData-03-description'),
    img: '/images/team/overseas',
  },
  {
    title: s('team.organizationsData-04-title'),
    description: s('team.organizationsData-04-description'),
    img: '/images/team/local',
  },
];
