import { TLocationDataType } from 'interfaces/mapInterface';

export const convertLocationToArray = (props: TLocationDataType): string[] => {
  const result: string[] = [props.ctp_cd.toString()];

  result.push(props.sig_cd ? props.sig_cd.toString() : '0');
  result.push(props.emd_cd ? props.emd_cd.toString() : '0');

  return result;
};

export const convertArrayToLocation = (props: string[]): TLocationDataType => {
  return {
    ctp_cd: parseInt(props[0]),
    sig_cd: props[1] ? (props[1] !== '0' ? parseInt(props[1]) : undefined) : undefined,
    emd_cd: props[2] ? (props[2] !== '0' ? parseInt(props[2]) : undefined) : undefined,
  };
};
