import useCloseWhenOutsideClick from '@hooks/useCloseWhenOutsideClick';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { ReactComponent as ChevronIcon } from '@assets/icons/chevron_left_icon.svg';
import LocationSelectorBody from '@components/common/selector/locationSelector/LocationSelectorBody';
import { getLocationNameList } from '@utils/getLocationName';
import i18n from '@utils/i18n';
import { useTranslation } from 'react-i18next';

type TProps = {
  selectorId: string;
  inputName: string;
  width?: string;
  backgroundColor?: string;
  isThirdItem?: boolean;
  required?: boolean;
};

// FormProvider로 감싸야 사용 가능
const LocationSelector = ({ selectorId, inputName, width = '100%', backgroundColor, isThirdItem = true, required = true }: TProps) => {
  const [isLocationSelectorOpen, setIsLocationSelectorOpen] = useCloseWhenOutsideClick(`#${selectorId}`);
  const {
    field: { name, onChange, value },
  } = useController({ name: inputName, rules: { required } });
  const activeLocale = i18n.language === 'ko' ? 'ko' : 'en';
  const { t } = useTranslation();

  const handleSelectorToggle = () => {
    setIsLocationSelectorOpen((prev) => !prev);
  };

  return (
    <Container id={selectorId} $width={width}>
      <input name={name} onChange={onChange} value={value} />
      <Header
        onClick={handleSelectorToggle}
        $isLocationSelected={value.length > 0}
        $isOpen={isLocationSelectorOpen}
        $backgroundColor={backgroundColor}
      >
        {t(getLocationNameList(value, isThirdItem, activeLocale))}
        <ChevronIcon />
      </Header>
      {isLocationSelectorOpen && (
        <LocationSelectorBody
          inputName={inputName}
          onSelectorClose={() => setIsLocationSelectorOpen(false)}
          backgroundColor={backgroundColor}
          isThirdItem={isThirdItem}
        />
      )}
    </Container>
  );
};

export default LocationSelector;

const Container = styled.div<{ $width: string }>`
  position: relative;
  width: ${({ $width }) => $width};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.newColors.white};

  input {
    display: none;
  }
`;

const Header = styled.div<{ $isLocationSelected: boolean; $isOpen: boolean; $backgroundColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.newColors.dark1};
  color: ${({ theme, $isLocationSelected }) => ($isLocationSelected ? theme.newColors.white : theme.newColors.grey2)};
  border-radius: ${({ $isOpen }) => ($isOpen ? '5px 5px 0 0' : '5px')};
  cursor: pointer;

  svg {
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'rotate(270deg)')};
  }
`;
