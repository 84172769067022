import { useQuery } from '@tanstack/react-query';
import { getTotalCarbonData } from 'api/mapApi';
import { CarbonBudgetDataType } from 'stores/mapStore';

export const useTotalCarbonNumQuery = (condition: CarbonBudgetDataType, enabled: boolean) => {
  const { location, startDate, endDate, dataType, tense } = condition;
  const formatStartDate = startDate.format('YYYYMM');
  const formatEndDate = endDate.format('YYYYMM');

  return useQuery({
    queryKey: ['carbon-budget', 'total', location, formatStartDate, formatEndDate, dataType],
    queryFn: () =>
      getTotalCarbonData({
        location,
        dataType,
        endDate: formatEndDate,
        startDate: formatStartDate,
      }),
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
