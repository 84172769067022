import { styled } from 'styled-components';
import Header from './header/Header';
import Footer from './Footer';
import { Outlet } from 'react-router';

const Layout = () => {
  return (
    <Container>
      <Header />
      <Flex>
        <Main>
          <Outlet />
        </Main>
        <Footer />
      </Flex>
    </Container>
  );
};

export default Layout;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  height: 100dvh;
`;

const Main = styled.main`
  flex: 1;

  & > div {
    height: 100%;
  }
`;

const Flex = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
`;
