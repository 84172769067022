import styled from 'styled-components';
import { ReactComponent as LogoWhiteIcon } from '@assets/icons/header/logo_white_icon.svg';
import { Link, useLocation } from 'react-router-dom';
import useWidthType from '@hooks/useWidthType';
import MobileHeader from './MobileHeader';
import { headerData } from '@static/headerData';
import LanguageChangeButton from '@components/layout/header/LanguageChangeButton';

const Header = () => {
  const { pathname } = useLocation();
  const widthType = useWidthType();

  if (!widthType) return null;
  return (
    <>
      {widthType !== 'desktop' ? (
        <MobileHeader />
      ) : (
        <Contanier>
          <Link to="/">
            <LogoWhiteIcon />
          </Link>
          <NavBar>
            {headerData.map((route) => (
              <Link key={route.name} to={route.link} className={pathname === route.link ? 'active' : ''}>
                {route.name}
              </Link>
            ))}
          </NavBar>
          <HeaderRight>
            <LanguageChangeButton />
          </HeaderRight>
        </Contanier>
      )}
    </>
  );
};

export default Header;

const Contanier = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.newColors.dark1};
  z-index: 3;
`;

const NavBar = styled.nav`
  display: flex;
  gap: 100px;
  font-size: 16px;
  font-variation-settings: 'wght' 500;
  letter-spacing: -0.32px;

  a.active {
    color: ${({ theme }) => theme.newColors.primary};
  }
`;

const HeaderRight = styled.div`
  display: flex;
  gap: 20px;
`;
