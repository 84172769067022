import { Feature, FeatureCollection } from '@turf/helpers';
import { TGetDistrictsDataResponse } from 'interfaces/districtInterface';
import { TGetMonthlyCarbonDataResponse, TPopupCarbonDataType } from 'interfaces/mapInterface';

const getMinMaxValue = (arr: TPopupCarbonDataType[]) => {
  const step = Math.floor(arr.length / 10);
  return { max: arr[step].total, min: arr[arr.length - 1].total };
};

export const convertDistrictsData = (districtData: TGetDistrictsDataResponse[], data: TGetMonthlyCarbonDataResponse) => {
  const result: FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  districtData.map((district) => {
    const carbonData = data.data.find(
      (item) => item.ctp_cd === district.ctp_cd && item.sig_cd === district.sig_cd && item.emd_cd === district.emd_cd,
    );
    const feature: Feature = {
      type: 'Feature',
      properties: {
        id: district.id,
        ctp_cd: district.ctp_cd,
        sig_cd: district.sig_cd,
        emd_cd: district.emd_cd,
        carbonData,
      },
      geometry: district.geometry,
    };
    result.features.push(feature);
  });

  return { data: result, ...getMinMaxValue(data.data) };
};
