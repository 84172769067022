import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18n from '@utils/i18n';

const LOCAL_LOCALE_KEY = 'i18nextLng';

const LanguageChangeButton = () => {
  const { t } = useTranslation();

  const changeLanguage = (lng: 'ko' | 'en') => {
    localStorage.setItem(LOCAL_LOCALE_KEY, lng);
    i18n.changeLanguage(lng);
  };

  const handleLanguageButtonClick = () => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'ko') {
      changeLanguage('en');
    } else {
      changeLanguage('ko');
    }
  };

  return <Button onClick={handleLanguageButtonClick}>{t('header.language')}</Button>;
};

export default LanguageChangeButton;

const Button = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-variation-settings: 'wght' 500;
  letter-spacing: -0.32px;
`;
