import s from '@utils/i18nextScan';

export const aboutData = {
  main: {
    title: s('about.data.main-title'),
  },
  goal: {
    title: 'Our Goal',
    content: s('about.data.goal-content'),
    goal: {
      text: [
        {
          title: s('about.data.goal-text01-title'),
          text: s('about.data.goal-text01-text'),
        },
        {
          title: s('about.data.goal-text02-title'),
          text: s('about.data.goal-text02-text'),
        },
        { title: s('about.data.goal-text03-title') },
        { title: s('about.data.goal-text04-title') },
        { title: s('about.data.goal-text05-title') },
        { title: s('about.data.goal-text05-title') },
      ],
      image: [
        { src: s('about.data-goal-img1') },
        { src: s('about.data-goal-img2') },
        { src: s('about.data-goal-img3') },
        { src: s('about.data-goal-img4') },
        { src: s('about.data-goal-img5') },
        { src: s('about.data-goal-img6') },
      ],
    },
  },
  purpose: {
    title: 'Our Purpose',
    content: s('about.data.purpose-content'),
    purpose: [s('about.data.purpose-text01'), s('about.data.purpose-text02'), s('about.data.purpose-text03')],
  },
  roadmap: {
    title: 'Our Roadmap',
    content: s('about.data.roadmap-content'),
    roadmap: {
      map: [
        { title: s('about.data.roadmap-mapTitle01'), src: '/images/about/roadmap/map1.png' },
        { title: s('about.data.roadmap-mapTitle02'), src: '/images/about/roadmap/map2.png' },
        { title: s('about.data.roadmap-mapTitle03'), src: '/images/about/roadmap/map3.png' },
      ],
      history: [
        {
          year: 2023,
          text1: s('about.data.roadmap-history01-text1'),
        },
        {
          year: 2024,
          text1: s('about.data.roadmap-history02-text1'),
        },
        {
          year: 2025,
          text1: s('about.data.roadmap-history03-text1'),
        },
        {
          year: 2026,
          text1: s('about.data.roadmap-history04-text1'),
        },
        {
          year: 2027,
          text1: s('about.data.roadmap-history05-text1'),
        },
      ],
      plan: [
        {
          title: s('about.data.plan01-title'),
          data: [
            s('about.data.roadmap-mapTitle01'),
            s('about.data.roadmap-mapTitle02'),
            s('about.data.roadmap-mapTitle03'),
            s('about.data.plan02-data04'),
          ],
        },
        {
          title: s('about.data.plan02-title'),
          data: [
            s('about.data.roadmap-mapTitle01'),
            s('about.data.roadmap-mapTitle02'),
            s('about.data.roadmap-mapTitle03'),
            s('about.data.plan02-data04'),
          ],
        },
        {
          title: s('common.future'),
          data: [
            s('about.data.plan03-data01'),
            s('about.data.plan03-data02'),
            s('about.data.plan03-data03'),
            s('about.data.plan03-data04'),
            s('about.data.plan03-data05'),
          ],
        },
      ],
    },
  },
};
