import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>404 ERROR</Title>
      <Content>
        <span>{t('notFound.description-01')}</span>
        <span>{t('notFound.description-02')}</span>
      </Content>
      <Link to={'/'}>
        <GoToHome>{t('notFound.goToHome')}</GoToHome>
      </Link>
    </Container>
  );
};

export default NotFound;

const GoToHome = styled.button`
  margin-top: 100px;
  padding: 0px 10px;
  height: 35px;

  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-variation-settings: 'wght' 500;
  line-height: 17px;
  letter-spacing: -0.28px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.gray};

  ${({ theme }) =>
    theme.media.mobile(`
      margin-top: 40px;
  `)}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  height: 100vh;
  height: 100svh;

  ${({ theme }) =>
    theme.media.mobile(`
      gap: 20px;
  `)}
`;

const Title = styled.h1`
  font-size: 60px;
  font-variation-settings: 'wght' 500;
  letter-spacing: -1.2px;

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 35px;
      line-height: normal;
      letter-spacing: -0.7px;
  `)}
`;

const Content = styled.span`
  font-size: 20px;
  font-variation-settings: 'wght' 400;
  line-height: 35px;
  letter-spacing: -0.4px;
  text-align: center;

  span {
    display: block;
  }
  ${({ theme }) =>
    theme.media.tablet(`
      white-space: pre-line;    
  `)}
  ${({ theme }) =>
    theme.media.mobile(`
      display: flex;
      flex-direction: column;
      max-width: 350px;
      font-size: 16px;
      line-height: 28px; /* 175% */
      letter-spacing: -0.32px;
      white-space: pre-line;    
  `)}
`;
