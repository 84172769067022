import { useQuery } from '@tanstack/react-query';
import { getMonthlyCarbonData } from 'api/mapApi';
import { Dayjs } from 'dayjs';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';

type TProps = {
  yearMonth: Dayjs;
  ctp_cd: number;
  dataType: TCarbonSourceDataType[];
};

export const useMontlyCarbonQuery = (condition: TProps, enabled: boolean) => {
  const { yearMonth, ctp_cd, dataType } = condition;
  const formatDate = yearMonth.format('YYYYMM');

  return useQuery({
    queryKey: ['carbon-budget', 'monhtly', ctp_cd, formatDate, dataType],
    queryFn: () =>
      getMonthlyCarbonData({
        yearMonth: formatDate,
        ctp_cd,
        dataType,
      }),
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
