import s from '@utils/i18nextScan';

export const ChartData = [
  {
    title: s('chartData.group01'),
    borderColor: '#ff8718',
    backgroundColor: 'rgba(255, 92, 0, 0.5)',
  },
  {
    title: s('chartData.group02'),
    borderColor: '#9E71FF',
    backgroundColor: 'rgba(158, 113, 255,0.5)',
  },
  {
    title: s('chartData.group03'),
    borderColor: '#60BCFF',
    backgroundColor: 'rgba(96, 188, 255, 0.5)',
  },
];
