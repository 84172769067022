import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Portal from './Portal';

type Props = {
  children: React.ReactElement;
  portalID: string;
  dimBg?: boolean;
  dimIndex?: number;
  isLoading?: boolean;
  isOpen: boolean;
  isCloseWhenAroundClick?: boolean;
  onRequestClose?: () => void;
};

const BasicModal = ({
  children,
  portalID,
  dimBg = true,
  dimIndex = 10,
  isLoading = false,
  isOpen,
  isCloseWhenAroundClick = true,
  onRequestClose,
}: Props) => {
  const dimBgRef = useRef(null);
  const [isCloseHandler, setIsCloseHandler] = useState(false);
  const [isCloseClicked, setIsCloseClicked] = useState(false);

  const onClickAroundModal = (e) => {
    e.target == ReactDOM.findDOMNode(dimBgRef.current) && isCloseWhenAroundClick && onRequestClose && onRequestClose();
  };

  useEffect(() => onRequestClose && setIsCloseHandler(true), [onRequestClose]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (isCloseClicked) return;
        if (onRequestClose) {
          onRequestClose();
          setIsCloseClicked(true);
        }
      }
    };

    if (isOpen && isCloseHandler && !isLoading) {
      document.addEventListener('keydown', handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }
  }, [isCloseHandler, isOpen, isLoading, isCloseClicked, onRequestClose]);

  useEffect(() => {
    if (isOpen) {
      setIsCloseClicked(false);
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Portal selector={portalID}>
          <DimBg $dimBg={dimBg} ref={dimBgRef} onClick={onClickAroundModal} $dimIndex={dimIndex}>
            {children}
          </DimBg>
        </Portal>
      )}
    </>
  );
};

export default BasicModal;

const DimBg = styled.div<{ $dimBg: boolean; $dimIndex: number }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  background-color: ${({ $dimBg }) => ($dimBg ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
  z-index: ${({ $dimIndex }) => $dimIndex};
`;
