import styled from 'styled-components';
import { aboutData } from '@static/aboutData';
import SwiperBox from './SwiperBox';
import AboutItem from './AboutItem';
import PurposeBox from './PurposeBox';
import Roadmap from './Roadmap';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <TopImageContainer>
        <TitleContainer>
          <h2>Korea Carbon Project</h2>
          <span className="bold">{t(aboutData.main.title)}</span>
        </TitleContainer>
      </TopImageContainer>
      <ContentsContainer>
        <AboutItem data={aboutData.goal}>
          <SwiperBox />
        </AboutItem>
        <AboutItem data={aboutData.purpose} contentGap={50}>
          <PurposeBox data={aboutData.purpose.purpose} />
        </AboutItem>
        <AboutItem data={aboutData.roadmap} contentGap={100}>
          <Roadmap />
        </AboutItem>
      </ContentsContainer>
    </Container>
  );
};

export default About;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  overflow-x: hidden;
`;

const TopImageContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 700px;
  background-image: url(/images/about/about_background.png);
  background-repeat: no-repeat;
  background-size: cover;

  ${({ theme }) =>
    theme.media.tablet(`
      padding-inline: 30px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      padding-inline: 20px;
      height: 500px;
  `)}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1248px;
  color: ${({ theme }) => theme.newColors.white};

  h2 {
    font-size: 35px;
    font-variation-settings: 'wght' 800;
    line-height: 50px; /* 142.857% */
    letter-spacing: -0.7px;
  }

  span.bold {
    font-size: 20px;
    font-variation-settings: 'wght' 500;
    line-height: 40px; /* 200% */
    letter-spacing: -0.4px;
    margin-top: 50px;
  }

  ${({ theme }) =>
    theme.media.tablet(`
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      span.bold {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px; /* 175% */
        letter-spacing: -0.32px;
      }
  `)}
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  max-width: 1248px;
  width: 100%;
  padding: 100px 0;

  ${({ theme }) =>
    theme.media.tablet(`
      padding: 80px 30px;
      gap: 80px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      padding: 50px 20px;
      gap: 50px;
  `)}
`;
