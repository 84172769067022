import styled from 'styled-components';
import { ReactComponent as ChevronIcon } from '@assets/icons/chevron_left_icon.svg';
import useCloseWhenOutsideClick from '@hooks/useCloseWhenOutsideClick';
import { DataTense } from '@static/mapData';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TProps = {
  selectorId: string;
  inputName: string;
  backgroundColor?: string;
  width?: string;
};

// FormProvider로 감싸야 함
const TenseSelector = ({ selectorId, inputName, backgroundColor, width = '100%' }: TProps) => {
  const { setValue } = useFormContext();
  const [isSelectorOpen, setIsSelectorOpen] = useCloseWhenOutsideClick(`#${selectorId}`);
  const {
    field: { name, onChange, value },
  } = useController({ name: inputName, rules: { required: true } });
  const { t } = useTranslation();

  const handleSelectorToggle = () => {
    setIsSelectorOpen((prev) => !prev);
  };

  const handleItemClick = (key: string) => () => {
    setValue(inputName, key, { shouldValidate: true });
    setIsSelectorOpen(false);
  };

  return (
    <Container id={selectorId} $width={width}>
      <input name={name} onChange={onChange} value={value} />
      <Header $isSelected={value !== ''} $isOpen={isSelectorOpen} onClick={handleSelectorToggle} $backgroundColor={backgroundColor}>
        {value === '' ? t('tenseSelector.choose') : t(DataTense[value as string].title)}
        <ChevronIcon />
      </Header>
      {isSelectorOpen && (
        <SelectList $backgroundColor={backgroundColor}>
          {Object.keys(DataTense).map((item) => (
            <SelectItem
              key={item}
              $isSelected={value === item}
              $isDisabled={DataTense[item].disabled}
              onClick={DataTense[item].disabled ? undefined : handleItemClick(item)}
            >
              {t(DataTense[item].title)}
            </SelectItem>
          ))}
        </SelectList>
      )}
    </Container>
  );
};

export default TenseSelector;

const Container = styled.div<{ $width: string }>`
  position: relative;
  width: ${({ $width }) => $width};

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.newColors.white};

  input {
    display: none;
  }
`;

const Header = styled.div<{ $isSelected: boolean; $isOpen: boolean; $backgroundColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.newColors.white : theme.newColors.grey2)};
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.newColors.dark1};
  border-radius: ${({ $isOpen }) => ($isOpen ? '5px 5px 0 0' : '5px')};
  cursor: pointer;

  svg {
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'rotate(270deg)')};
  }
`;

const SelectList = styled.ul<{ $backgroundColor?: string }>`
  position: absolute;
  top: 40px;
  left: 0;

  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.newColors.dark1};
  border-radius: 0 0 5px 5px;
  padding: 10px;
  z-index: 2;
`;

const SelectItem = styled.li<{ $isSelected: boolean; $isDisabled: boolean }>`
  color: ${({ theme, $isSelected, $isDisabled }) =>
    $isDisabled ? theme.newColors.grey1 : $isSelected ? theme.newColors.primary : theme.newColors.white};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;
