import LocationSelectorItem from '@components/common/selector/locationSelector/LocationSelectorItem';
import { locationData } from '@static/locationData';
import i18n from '@utils/i18n';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

type TProps = {
  inputName: string;
  onSelectorClose: () => void;
  isThirdItem?: boolean;
  backgroundColor?: string;
};

const LocationSelectorBody = ({ inputName, onSelectorClose, isThirdItem = true, backgroundColor }: TProps) => {
  const { getValues, setValue } = useFormContext();
  const [firstItem, setFirstItem] = useState<string | undefined>(getValues(inputName)[0]);
  const [secondItem, setSecondItem] = useState<string | undefined>(getValues(inputName)[1]);
  const [thirdItem, setThirdItem] = useState<string | undefined>(getValues(inputName)[2]);
  const activeLocale = i18n.language === 'ko' ? 'ko' : 'en';

  const handleFirstItemSelect = (id: string) => {
    setFirstItem(id);
    setSecondItem(undefined);
    setThirdItem(undefined);
  };

  const handleSecondItemSelect = (id: string) => {
    setSecondItem(id);
    setThirdItem(undefined);

    if (!isThirdItem) {
      setValue(inputName, [firstItem, id], { shouldValidate: true });
      onSelectorClose();
    }
  };

  const handleThirdItemSelect = (id: string) => {
    setThirdItem(id);
    if (!firstItem || !secondItem) return;
    setValue(inputName, [firstItem, secondItem, id], { shouldValidate: true });
    onSelectorClose();
  };

  return (
    <Container $backgroundColor={backgroundColor}>
      <LocationItem>
        <LocationSelectorItem
          locationList={Object.keys(locationData).map((key) => {
            return { id: key, name: locationData[key][activeLocale] };
          })}
          selectedId={firstItem}
          onSelectItem={handleFirstItemSelect}
        />
      </LocationItem>
      <LocationItem>
        {firstItem && (
          <LocationSelectorItem
            locationList={Object.keys(locationData[firstItem].children).map((key) => {
              return { id: key, name: locationData[firstItem].children[key][activeLocale] };
            })}
            selectedId={secondItem}
            onSelectItem={handleSecondItemSelect}
            isBorder={!isThirdItem && false}
          />
        )}
      </LocationItem>
      {isThirdItem && (
        <LocationItem>
          {firstItem && secondItem && (
            <LocationSelectorItem
              locationList={Object.keys(locationData[firstItem].children[secondItem].children).map((key) => {
                return { id: key, name: locationData[firstItem].children[secondItem].children[key][activeLocale] };
              })}
              selectedId={thirdItem}
              onSelectItem={handleThirdItemSelect}
              isBorder={false}
            />
          )}
        </LocationItem>
      )}
    </Container>
  );
};

export default LocationSelectorBody;

const Container = styled.div<{ $backgroundColor?: string }>`
  position: absolute;
  top: 40px;
  left: 0;

  display: flex;
  gap: 5px;
  width: 100%;
  height: 190px;
  padding: 10px;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.newColors.dark1};
  border-radius: 0 0 5px 5px;
  z-index: 2;
`;

const LocationItem = styled.div`
  width: 100%;
`;
