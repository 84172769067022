import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';

type Props = {
  children?: React.ReactNode;
  selector: string;
};

export default function Portal({ children, selector }: Props) {
  const [element, setElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setElement(document.querySelector(selector) as HTMLElement);
    document.body.style.overflow = 'hidden';
    document.body.style.touchAction = 'none';

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.touchAction = 'auto';
    };
  }, [selector]);
  return element && children ? createPortal(children, element) : null;
}
