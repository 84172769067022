import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  text: string;
  contentGap?: number;
  children?: ReactNode;
};

const ApproachItem = ({ title, text, contentGap, children }: Props) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Text>{text}</Text>
      {children && <Content $gap={contentGap}>{children}</Content>}
    </Container>
  );
};

export default ApproachItem;

const Container = styled.div``;

const Title = styled.div`
  color: ${({ theme }) => theme.newColors.white};
  font-size: 35px;
  font-variation-settings: 'wght' 800;
  line-height: 44px; /* 166.667% */
  letter-spacing: -0.7px;

  ${({ theme }) =>
    theme.media.tablet(`
      font-size: 25px;
      line-height: 40px; /* 160% */
      letter-spacing: -0.5px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 20px;
      font-weight: 700;
      line-height: 30px; /* 150% */
      letter-spacing: -0.4px;
  `)}
`;

const Text = styled.div`
  margin-top: 30px;

  color: ${({ theme }) => theme.newColors.white};
  font-size: 20px;
  font-variation-settings: 'wght' 400;
  line-height: 40px; /* 200% */
  letter-spacing: -0.4px;

  ${({ theme }) =>
    theme.media.tablet(`
      font-size: 18px;
      line-height: 30px; /* 166.667% */
      letter-spacing: -0.36px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 16px;
      line-height: 28px; /* 175% */
      letter-spacing: -0.32px;
  `)}
`;

const Content = styled.div<{ $gap?: number }>`
  margin-top: ${({ $gap }) => ($gap ? `${$gap}px` : '100px')};
  width: 100%;

  ${({ theme }) =>
    theme.media.tablet(`
      margin-top: 50px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      margin-top: 50px;
  `)}
`;
