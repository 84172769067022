import s from '@utils/i18nextScan';
import { INetworkData } from 'interfaces/networkInterface';

export const networkLegendData = {
  snu: { type: 'snu', title: s('network.networkLegendData-snu'), color: '#A07BFF' },
  khu: { type: 'khu', title: s('network.networkLegendData-khu'), color: '#FF7FA1' },
  jnu: { type: 'jnu', title: s('network.networkLegendData-jnu'), color: '#52EEA3' },
  knu: { type: 'knu', title: s('network.networkLegendData-knu'), color: '#FFC538' },
  partner: { type: 'partner', title: s('network.networkLegendData-partner'), color: '#20CBDD' },
};

export const networkData: INetworkData[] = [
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-snu220'),
    coordinates: [126.954, 37.464],
    equipments: [
      {
        name: 'EM27/SUN Serial Number 142',
        materials: ['XCO2', 'XCH4', 'XCO'],
        year: '2020',
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
      {
        name: 'EM27/SUN Serial Number 144',
        materials: ['XCO2', 'XCH4', 'XCO'],
        year: '2021',
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-Guksubong'),
    coordinates: [126.9555, 37.46251],
    equipments: [
      {
        name: 'SNUCO2M (LI-850)',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-Yongsan'),
    coordinates: [126.9637, 37.52384],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2018', operate_org: s('common.snu'), owner: s('common.snu') },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-NamsanTower-upper'),
    coordinates: [126.9883, 37.5512],
    equipments: [
      {
        name: 'SNUCO2M (LI-850)',
        materials: ['CO2 Concentration'],
        year: '2019',
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'G2401',
        materials: ['CO2 Concentration'],
        year: s('network.networkData-scheduled2024'),
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Bulgwang'),
    coordinates: [126.9340046302, 37.6109990655],
    equipments: [
      {
        name: 'G2301',
        materials: ['CO2 Concentration'],
        year: '2022',
        operate_org: s('network.networkData-NIER'),
        owner: s('network.networkData-NIER'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Ansan'),
    coordinates: [126.8283102228, 37.3201871597],
    equipments: [
      {
        name: 'G2301',
        materials: ['CO2 Concentration'],
        year: '2022',
        operate_org: s('network.networkData-NIER'),
        owner: s('network.networkData-NIER'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Yangpyeong'),
    coordinates: [127.410568468, 37.4951501463],
    equipments: [
      {
        name: 'T360M',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: s('network.networkData-MAMO'),
        owner: s('network.networkData-MAMO'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '관악산',
    coordinates: [126.9659244073, 37.4446938065],
    equipments: [
      {
        name: 'G2301',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: s('network.networkData-SIHE'),
        owner: s('network.networkData-SIHE'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-NamsanTower-lower'),
    coordinates: [126.9880222185, 37.5509968327],
    equipments: [
      {
        name: 'G2301-i',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: s('network.networkData-SIHE'),
        owner: s('network.networkData-SIHE'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Olpark'),
    coordinates: [127.1160158535, 37.5209837604],
    equipments: [
      {
        name: 'G2301-i',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: s('network.networkData-SIHE'),
        owner: s('network.networkData-SIHE'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Pyeongtaek'),
    coordinates: [126.9315713448, 36.9859358327],
    equipments: [
      {
        name: 'G2401',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: s('network.networkData-GGIHE'),
        owner: s('network.networkData-GGIHE'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Gimpo'),
    coordinates: [126.552930978, 37.7153305585],
    equipments: [
      {
        name: 'G2401',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: s('network.networkData-GGIHE'),
        owner: s('network.networkData-GGIHE'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Naepo'),
    coordinates: [126.6676036998, 36.6531629999],
    equipments: [
      {
        name: 'G2301',
        materials: ['CO2 Concentration'],
        year: '2022',
        operate_org: s('network.networkData-CNIHE'),
        owner: s('network.networkData-CNIHE'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Hongneung'),
    coordinates: [127.0475117, 37.603306],
    equipments: [
      {
        name: 'SNUCO2M (LI-850)',
        materials: ['CO2 Concentration'],
        year: '2023',
        operate_org: s('network.networkData-KIST'),
        owner: s('network.networkData-KIST'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Jungnang'),
    coordinates: [127.0794, 37.5906],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2015-2019',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Gwanghwa'),
    coordinates: [126.9779, 37.5724],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2015-2019',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Gajwa'),
    coordinates: [126.9136, 37.5837],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2015-2019',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Guro'),
    coordinates: [126.8945, 37.4844],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2015',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Anyang'),
    coordinates: [126.9618, 37.3943],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2015',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Yeouido'),
    coordinates: [126.9206, 37.5345],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2015',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Bucheon'),
    coordinates: [126.7781, 37.5426],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2015',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Songdo'),
    coordinates: [126.6547, 37.3825],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2017',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Ilsan'),
    coordinates: [126.7307, 37.685],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2017',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Yongin'),
    coordinates: [127.2669, 37.3362],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2017',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Nowon'),
    coordinates: [127.0566, 37.6544],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2017',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Seongnam'),
    coordinates: [127.1422, 37.441],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2017',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Ttukseom'),
    coordinates: [127.0597, 37.5323],
    equipments: [
      {
        name: 'cambell',
        materials: ['CO2 Flux'],
        year: '2017',
        operate_org: s('network.networkData-NIMS'),
        owner: s('network.networkData-NIMS'),
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-Siheung'),
    coordinates: [126.7173, 37.33645],
    equipments: [
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'LI-7500RS',
        materials: ['CO2 Flux'],
        year: '2024',
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'CSAT3B',
        materials: ['Wind direction and speed'],
        year: '2024',
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'Biomet System',
        materials: ['Weather sensor'],
        year: '2024',
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-Namsan'),
    coordinates: [126.9921, 37.5512],
    equipments: [
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'LI-7500RS',
        materials: ['CO2 Flux'],
        year: '2023',
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'CSAT3B',
        materials: ['Wind direction and speed'],
        year: '2023',
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'Biomet System',
        materials: ['Weather sensor'],
        year: '2023',
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'LI-7815',
        materials: ['CO2 Concentration'],
        year: '2023',
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'FloX',
        materials: ['Sun-Induced Fluorescence'],
        year: '2023',
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
    ],
  },
  {
    type: 'khu',
    color: networkLegendData['khu']['color'],
    manage_org: networkLegendData['khu']['title'],
    location: s('network.networkData-Namsan'),
    // ISSUE: 같은 위경도 좌표값으로 인한 수정
    coordinates: [126.9922, 37.5513],
    equipments: [
      {
        name: 'LI-8250, EGM-5',
        materials: ['Soil CO2 Flux'],
        year: '2023',
        operate_org: s('common.khu'),
        owner: s('common.khu'),
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-Changgyeonggung'),
    coordinates: [126.9952, 37.5834],
    equipments: [
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'LI-7500RS',
        materials: ['CO2 Flux'],
        year: '2024',
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'CSAT3B',
        materials: ['Wind direction and speed'],
        year: '2024',
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'Biomet System',
        materials: ['Weather sensor'],
        year: '2024',
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
      {
        name: 'LI-7815',
        materials: ['CO2 Concentration'],
        year: '2024',
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'FloX',
        materials: ['Sun-Induced Fluorescence'],
        year: s('network.networkData-scheduled2024'),
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
    ],
  },
  {
    type: 'khu',
    color: networkLegendData['khu']['color'],
    manage_org: networkLegendData['khu']['title'],
    location: s('network.networkData-Changgyeonggung'),
    // ISSUE: 같은 위경도 좌표값으로 인한 수정
    coordinates: [126.9953, 37.5835],
    equipments: [
      {
        name: 'LI-8250, EGM-5',
        materials: ['Soil CO2 Flux'],
        year: '2023',
        operate_org: s('common.khu'),
        owner: s('common.khu'),
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-Seonjeongneung'),
    coordinates: [127.0492, 37.5088],
    equipments: [
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'LI-7500RS',
        materials: ['CO2 Flux'],
        year: s('common.planning'),
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'CSAT3B',
        materials: ['Wind direction and speed'],
        year: s('common.planning'),
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'Biomet System',
        materials: ['Weather sensor'],
        year: s('common.planning'),
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'LI-7815',
        materials: ['CO2 Concentration'],
        year: s('common.planning'),
        operate_org: s('common.snu'),
        owner: s('network.networkData-NIFoS'),
      },
      {
        name: 'FloX',
        materials: ['Sun-Induced Fluorescence'],
        year: s('common.planning'),
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
    ],
  },
  {
    type: 'khu',
    color: networkLegendData['khu']['color'],
    manage_org: networkLegendData['khu']['title'],
    location: s('network.networkData-Seonjeongneung'),
    // ISSUE: 같은 위경도 좌표값으로 인한 수정
    coordinates: [127.0493, 37.5089],
    equipments: [
      {
        name: 'LI-8250, EGM-5',
        materials: ['Soil CO2 Flux'],
        year: s('common.planning'),
        operate_org: s('common.khu'),
        owner: s('common.khu'),
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-GG-Gangju-TCK'),
    coordinates: [127.31722, 37.30472],
    equipments: [{ name: 'LI-7500A', materials: ['CO2 Flux'], year: '2015', operate_org: s('common.snu'), owner: s('common.snu') }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-GG-Gangju-TBK'),
    coordinates: [127.3138, 37.3038],
    equipments: [{ name: 'LI-7500A', materials: ['CO2 Flux'], year: '2016', operate_org: s('common.snu'), owner: s('common.snu') }],
  },
  {
    type: 'khu',
    color: networkLegendData['khu']['color'],
    manage_org: networkLegendData['khu']['title'],
    location: s('network.networkData-Guri'),
    coordinates: [127.14083, 37.57861],
    equipments: [
      {
        name: 'EGM-5',
        materials: ['Soil CO2 Flux'],
        year: '2023',
        operate_org: s('common.khu'),
        owner: s('common.khu'),
      },
    ],
  },

  {
    type: 'jnu',
    color: networkLegendData['jnu']['color'],
    manage_org: networkLegendData['jnu']['title'],
    location: s('network.networkData-Naju-NRK01'),
    coordinates: [126.820921, 35.027536],
    equipments: [
      {
        name: 'LI-7500RS',
        materials: ['CO2 Flux'],
        year: '2020',
        operate_org: s('common.jnu'),
        owner: s('common.jnu'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-Naju-NRK02'),
    coordinates: [126.821979, 35.026938],
    equipments: [
      {
        name: 'LI-7500A, LI-7700',
        materials: ['CO2', 'CH4 Flux'],
        year: '2021',
        operate_org: s('common.jnu'),
        owner: s('network.networkData-KARI'),
      },
    ],
  },
  {
    type: 'jnu',
    color: networkLegendData['jnu']['color'],
    manage_org: networkLegendData['jnu']['title'],
    location: s('network.networkData-HFK'),
    coordinates: [126.569925, 34.553807],
    equipments: [
      {
        name: 'LI-7500A',
        materials: ['CO2 Flux'],
        year: '2024',
        operate_org: s('common.jnu'),
        owner: s('common.jnu'),
      },
    ],
  },
  {
    type: 'jnu',
    color: networkLegendData['jnu']['color'],
    manage_org: networkLegendData['jnu']['title'],
    location: s('network.networkData-HSK'),
    coordinates: [126.6442, 34.6402],
    equipments: [
      {
        name: 'LI-7500DS',
        materials: ['CO2 Flux'],
        year: '2024',
        operate_org: s('common.jnu'),
        owner: s('common.jnu'),
      },
    ],
  },
  {
    type: 'jnu',
    color: networkLegendData['jnu']['color'],
    manage_org: networkLegendData['jnu']['title'],
    location: s('network.networkData-HOK'),
    coordinates: [126.572402, 34.555123],
    equipments: [
      {
        name: 'LI-7500DS',
        materials: ['CO2 Flux'],
        year: '2024',
        operate_org: s('common.jnu'),
        owner: s('common.jnu'),
      },
    ],
  },

  {
    type: 'knu',
    color: networkLegendData['knu']['color'],
    manage_org: networkLegendData['knu']['title'],
    location: s('network.networkData-Chuncheon-knu'),
    coordinates: [127.7434837, 37.8719823],
    equipments: [
      {
        name: 'SNUCO2M (LI-850)',
        materials: ['CO2 Concentration'],
        year: '2023',
        operate_org: s('common.knu'),
        owner: s('common.knu'),
      },
    ],
  },
  {
    type: 'knu',
    color: networkLegendData['knu']['color'],
    manage_org: networkLegendData['knu']['title'],
    location: s('network.networkData-Chuncheon-Geumbyeongsan'),
    coordinates: [127.74853, 37.805569],
    equipments: [
      {
        name: 'SNUCO2M (LI-850)',
        materials: ['CO2 Concentration'],
        year: '2023',
        operate_org: s('common.knu'),
        owner: s('common.knu'),
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-Gwanaksan-hardwood'),
    coordinates: [126.957, 37.457],
    equipments: [{ name: 'EGM-5', materials: ['Soil CO2 Flux'], year: '2024', operate_org: s('common.snu'), owner: s('common.snu') }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-Gwanaksan-softwood'),
    coordinates: [126.9568, 37.4571],
    equipments: [{ name: 'EGM-5', materials: ['Soil CO2 Flux'], year: '2024', operate_org: s('common.snu'), owner: s('common.snu') }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: s('network.networkData-Baegunsan-softwood'),
    coordinates: [127.6039, 35.0315],
    equipments: [
      {
        name: 'Li-850',
        materials: ['Soil CO2 Flux'],
        year: s('network.networkData-scheduled2024'),
        operate_org: s('common.snu'),
        owner: s('common.snu'),
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-pnu-701'),
    coordinates: [129.075052, 35.2320481],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2024', operate_org: s('common.pnu'), owner: s('common.pnu') },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: s('network.networkData-pnu-303'),
    coordinates: [129.0811203, 35.2306586],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2024', operate_org: s('common.pnu'), owner: s('common.pnu') },
    ],
  },
];

export const networkColumnData = {
  name: s('network.networkColumnData-name'),
  materials: s('network.networkColumnData-materials'),
  year: s('network.networkColumnData-year'),
  owner: s('network.networkColumnData-owner'),
  operate_org: s('network.networkColumnData-operate_org'),
};
