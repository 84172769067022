import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { CarbonLegend } from '@static/mapData';
import theme from '@styles/theme';
import { FeatureCollection, Position } from '@turf/helpers';
import { hexToRGBArray } from '@utils/hexToRGBA';
import { TCarbonType, TPopupCarbonDataType } from 'interfaces/mapInterface';

type Props = {
  id: string;
  data: FeatureCollection;
  min: number;
  max: number;
  carbonType: TCarbonType;
  selectedId: string | undefined;
  onClick: (data: { id: number; data: TPopupCarbonDataType; position: Position }) => void;
};

const findIndex = (min: number, max: number, steps: number, value: number): number => {
  const stepSize = (max - min) / (steps - 1);

  let index = 0;
  let closestDifference = Math.abs(min - value);

  for (let i = 1; i < steps; i++) {
    const currentValue = min + i * stepSize;
    const currentDifference = Math.abs(currentValue - value);
    if (currentDifference < closestDifference) {
      closestDifference = currentDifference;
      index = i;
    }
  }

  return index;
};

const getColorValue = (value: number, min: number, max: number, type: TCarbonType) => {
  const color = CarbonLegend[type]['color'];
  const index = findIndex(min, max, 10, value);

  return hexToRGBArray(color[index]) as [number, number, number];
};

const CarbonGeojsonLayer = ({ id, data, min, max, carbonType, selectedId, onClick }: Props) => {
  return new GeoJsonLayer({
    id,
    data,
    stroked: true,
    filled: true,
    pickable: true,
    autoHighlight: true,
    getFillColor: (d) => getColorValue(d.properties?.carbonData.total, min, max, carbonType),
    getLineColor: [0, 0, 0, 255],
    getLineWidth: 5,
    lineWidthScale: 5,
    highlightColor: hexToRGBArray(theme.newColors.primary),
    updateTriggers: { getFillColor: selectedId },
    onClick: (info) =>
      onClick({ id: info.object.properties.id, data: info.object.properties.carbonData, position: info.coordinate as [number, number] }),
  });
};

export default CarbonGeojsonLayer;
