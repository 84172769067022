import useWidthType from '@hooks/useWidthType';
import { networkLegendData } from '@static/networkData';
import hexToRGBA from '@utils/hexToRGBA';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {};

const LegendPanel = (props: Props) => {
  const { t } = useTranslation();
  const widthType = useWidthType();
  const dataKeys = Object.keys(networkLegendData);
  const reorderedDataKeys = widthType === 'mobile' ? [dataKeys[dataKeys.length - 1], ...dataKeys.slice(0, -1)] : dataKeys;

  return (
    <Container>
      <Text className="title">{t('network.legendPanel-title')}</Text>
      <ItemContainer>
        {reorderedDataKeys.map((key) => (
          <Item key={networkLegendData[key].title}>
            <Marker $color={networkLegendData[key].color} />
            <Text>{t(networkLegendData[key].title)}</Text>
          </Item>
        ))}
      </ItemContainer>
    </Container>
  );
};

export default LegendPanel;

const Container = styled.div`
  display: flex;
  width: 200px;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 5px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark1, 0.8)};
  box-shadow: 2px 5px 10px 0px ${({ theme }) => hexToRGBA(theme.newColors.black, 0.3)};
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  position: absolute;
  bottom: 40px;
  left: 5px;
  z-index: 3;

  ${({ theme }) =>
    theme.media.mobile(`
      width: 100%;
      gap: 10px;
      left: 0;
      bottom: 0;
  `)}
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) =>
    theme.media.mobile(`
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
  `)}
`;

const Marker = styled.div<{ $color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  flex-shrink: 0;

  background-color: ${({ $color }) => $color};
  border: 2px solid ${({ $color }) => hexToRGBA($color, 0.3)};
  background-clip: padding-box;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.newColors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  &.title {
    font-weight: 700;
  }

  ${({ theme }) =>
    theme.media.mobile(`
      &:not(.title) {
        font-size: 14px;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
      }
  `)}
`;
