type TLocationDataType = {
  [key: string]: {
    ko: string;
    en: string;
    disabled?: boolean;
    children?: TLocationDataType;
  };
};

export const locationData = {
  '11000000': {
    ko: '서울특별시',
    en: 'Seoul',
    children: {
      '0': {
        ko: '전체',
        en: 'All',
        children: { '0': { ko: '전체', en: 'All' } },
      },
      '11110000': {
        ko: '종로구',
        en: 'Jongno-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11110101': { ko: '청운동', en: 'Cheongun-dong' },
          '11110102': { ko: '신교동', en: 'Singyo-dong' },
          '11110103': { ko: '궁정동', en: 'Gungjeong-dong' },
          '11110104': { ko: '효자동', en: 'Hyoja-dong' },
          '11110105': { ko: '창성동', en: 'Changseong-dong' },
          '11110106': { ko: '통의동', en: 'Tongui-dong' },
          '11110107': { ko: '적선동', en: 'Jeokseon-dong' },
          '11110108': { ko: '통인동', en: 'Tongin-dong' },
          '11110109': { ko: '누상동', en: 'Nusang-dong' },
          '11110110': { ko: '누하동', en: 'Nuha-dong' },
          '11110111': { ko: '옥인동', en: 'Ogin-dong' },
          '11110112': { ko: '체부동', en: 'Chebu-dong' },
          '11110113': { ko: '필운동', en: 'Pirun-dong' },
          '11110114': { ko: '내자동', en: 'Naeja-dong' },
          '11110115': { ko: '사직동', en: 'Sajik-dong' },
          '11110116': { ko: '도렴동', en: 'Doryeom-dong' },
          '11110117': { ko: '당주동', en: 'Dangju-dong' },
          '11110118': { ko: '내수동', en: 'Naesu-dong' },
          '11110119': { ko: '세종로', en: 'Sejongno' },
          '11110120': { ko: '신문로1가', en: 'Sinmunno 1(il)-ga' },
          '11110121': { ko: '신문로2가', en: 'Sinmunno 2(i)-ga' },
          '11110122': { ko: '청진동', en: 'Cheongjin-dong' },
          '11110123': { ko: '서린동', en: 'Seorin-dong' },
          '11110124': { ko: '수송동', en: 'Susong-dong' },
          '11110125': { ko: '중학동', en: 'Junghak-dong' },
          '11110126': { ko: '종로1가', en: 'Jongno 1(il)-ga' },
          '11110127': { ko: '공평동', en: 'Gongpyeong-dong' },
          '11110128': { ko: '관훈동', en: 'Gwanhun-dong' },
          '11110129': { ko: '견지동', en: 'Gyeonji-dong' },
          '11110130': { ko: '와룡동', en: 'Waryong-dong' },
          '11110131': { ko: '권농동', en: 'Gwonnong-dong' },
          '11110132': { ko: '운니동', en: 'Unni-dong' },
          '11110133': { ko: '익선동', en: 'Ikseon-dong' },
          '11110134': { ko: '경운동', en: 'Gyeongun-dong' },
          '11110135': { ko: '관철동', en: 'Gwancheol-dong' },
          '11110136': { ko: '인사동', en: 'Insa-dong' },
          '11110137': { ko: '낙원동', en: 'Nagwon-dong' },
          '11110138': { ko: '종로2가', en: 'Jongno 2(i)-ga' },
          '11110139': { ko: '팔판동', en: 'Palpan-dong' },
          '11110140': { ko: '삼청동', en: 'Samcheong-dong' },
          '11110141': { ko: '안국동', en: 'Anguk-dong' },
          '11110142': { ko: '소격동', en: 'Sogyeok-dong' },
          '11110143': { ko: '화동', en: 'Hwa-dong' },
          '11110144': { ko: '사간동', en: 'Sagan-dong' },
          '11110145': { ko: '송현동', en: 'Songhyeon-dong' },
          '11110146': { ko: '가회동', en: 'Gahoe-dong' },
          '11110147': { ko: '재동', en: 'Jae-dong' },
          '11110148': { ko: '계동', en: 'Gye-dong' },
          '11110149': { ko: '원서동', en: 'Wonseo-dong' },
          '11110150': { ko: '훈정동', en: 'Hunjeong-dong' },
          '11110151': { ko: '묘동', en: 'Myo-dong' },
          '11110152': { ko: '봉익동', en: 'Bongik-dong' },
          '11110153': { ko: '돈의동', en: 'Donui-dong' },
          '11110154': { ko: '장사동', en: 'Jangsa-dong' },
          '11110155': { ko: '관수동', en: 'Gwansu-dong' },
          '11110156': { ko: '종로3가', en: 'Jongno 3(sam)-ga' },
          '11110157': { ko: '인의동', en: 'Inui-dong' },
          '11110158': { ko: '예지동', en: 'Yeji -dong' },
          '11110159': { ko: '원남동', en: 'Wonnam-dong' },
          '11110160': { ko: '연지동', en: 'Yeonji-dong' },
          '11110161': { ko: '종로4가', en: 'Jongno 4(sa)-ga' },
          '11110162': { ko: '효제동', en: 'Hyoje-dong' },
          '11110163': { ko: '종로5가', en: 'Jongno 5(o)-ga' },
          '11110164': { ko: '종로6가', en: 'Jongno 6(yuk)-ga' },
          '11110165': { ko: '이화동', en: 'Ihwa-dong' },
          '11110166': { ko: '연건동', en: 'Yeongeon-dong' },
          '11110167': { ko: '충신동', en: 'Chungsin-dong' },
          '11110168': { ko: '동숭동', en: 'Dongsung-dong' },
          '11110169': { ko: '혜화동', en: 'Hyehwa-dong' },
          '11110170': { ko: '명륜1가', en: 'Myeongnyun 1(il)-ga' },
          '11110171': { ko: '명륜2가', en: 'Myeongnyun 2(i)-ga' },
          '11110172': { ko: '명륜4가', en: 'Myeongnyun 4(sa)-ga' },
          '11110173': { ko: '명륜3가', en: 'Myeongnyun 3(sam)-ga' },
          '11110174': { ko: '창신동', en: 'Changsin-dong' },
          '11110175': { ko: '숭인동', en: 'Sungin-dong' },
          '11110176': { ko: '교남동', en: 'Gyonam-dong' },
          '11110177': { ko: '평동', en: 'Pyeong-dong' },
          '11110178': { ko: '송월동', en: 'Songwol-dong' },
          '11110179': { ko: '홍파동', en: 'Hongpa-dong' },
          '11110180': { ko: '교북동', en: 'Gyobuk-dong' },
          '11110181': { ko: '행촌동', en: 'Haengchon-dong' },
          '11110182': { ko: '구기동', en: 'Gugi-dong' },
          '11110183': { ko: '평창동', en: 'Pyeongchang-dong' },
          '11110184': { ko: '부암동', en: 'Buam-dong' },
          '11110185': { ko: '홍지동', en: 'Hongji-dong' },
          '11110186': { ko: '신영동', en: 'Sinyeong-dong' },
          '11110187': { ko: '무악동', en: 'Muak-dong' },
        },
      },
      '11140000': {
        ko: '중구',
        en: 'Jung-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11140101': { ko: '무교동', en: 'Mugyo-dong' },
          '11140102': { ko: '다동', en: 'Da-dong' },
          '11140103': { ko: '태평로1가', en: 'Taepyeongno 1(il)-ga' },
          '11140104': { ko: '을지로1가', en: 'Euljiro 1(il)-ga' },
          '11140105': { ko: '을지로2가', en: 'Euljiro 2(i)-ga' },
          '11140106': { ko: '남대문로1가', en: 'Namdaemunno 1(il)-ga' },
          '11140107': { ko: '삼각동', en: 'Samgak-dong' },
          '11140108': { ko: '수하동', en: 'Suha-dong' },
          '11140109': { ko: '장교동', en: 'Janggyo-dong' },
          '11140110': { ko: '수표동', en: 'Supyo-dong' },
          '11140111': { ko: '소공동', en: 'Sogong-dong' },
          '11140112': { ko: '남창동', en: 'Namchang-dong' },
          '11140113': { ko: '북창동', en: 'Bukchang-dong' },
          '11140114': { ko: '태평로2가', en: 'Taepyeongno 2(i)-ga' },
          '11140115': { ko: '남대문로2가', en: 'Namdaemunno 2(i)-ga' },
          '11140116': { ko: '남대문로3가', en: 'Namdaemunno 3(sam)-ga' },
          '11140117': { ko: '남대문로4가', en: 'Namdaemunno 4(sa)-ga' },
          '11140118': { ko: '남대문로5가', en: 'Namdaemunno 5(o)-ga' },
          '11140119': { ko: '봉래동1가', en: 'Bongnae-dong 1(il)-ga' },
          '11140120': { ko: '봉래동2가', en: 'Bongnae-dong 2(i)-ga' },
          '11140121': { ko: '회현동1가', en: 'Hoehyeon-dong 1(il)-ga' },
          '11140122': { ko: '회현동2가', en: 'Hoehyeon-dong 2(i)-ga' },
          '11140123': { ko: '회현동3가', en: 'Hoehyeon-dong 3(sam)-ga' },
          '11140124': { ko: '충무로1가', en: 'Chungmuro 1(il)-ga' },
          '11140125': { ko: '충무로2가', en: 'Chungmuro 2(i)-ga' },
          '11140126': { ko: '명동1가', en: 'Myeong-dong 1(il)-ga' },
          '11140127': { ko: '명동2가', en: 'Myeong-dong 2(i)-ga' },
          '11140128': { ko: '남산동1가', en: 'Namsan-dong 1(il)-ga' },
          '11140129': { ko: '남산동2가', en: 'Namsan-dong 2(i)-ga' },
          '11140130': { ko: '남산동3가', en: 'Namsan-dong 3(sam)-ga' },
          '11140131': { ko: '저동1가', en: 'Jeo-dong 1(il)-ga' },
          '11140132': { ko: '충무로4가', en: 'Chungmuro 4(sa)-ga' },
          '11140133': { ko: '충무로5가', en: 'Chungmuro 5(o)-ga' },
          '11140134': { ko: '인현동2가', en: 'Inhyeon-dong 2(i)-ga' },
          '11140135': { ko: '예관동', en: 'Yegwan-dong' },
          '11140136': { ko: '묵정동', en: 'Mukjeong-dong' },
          '11140137': { ko: '필동1가', en: 'Pil-dong 1(il)-ga' },
          '11140138': { ko: '필동2가', en: 'Pildong 2(i)-ga' },
          '11140139': { ko: '필동3가', en: 'Pil-dong 3(sam)-ga' },
          '11140140': { ko: '남학동', en: 'Namhak-dong' },
          '11140141': { ko: '주자동', en: 'Juja-dong' },
          '11140142': { ko: '예장동', en: 'Yejang-dong' },
          '11140143': { ko: '장충동1가', en: 'Jangchung-dong 1(il)-ga' },
          '11140144': { ko: '장충동2가', en: 'Jangchung-dong 2(i)-ga' },
          '11140145': { ko: '광희동1가', en: 'Gwanghui-dong 1(il)-ga' },
          '11140146': { ko: '광희동2가', en: 'Gwanghui-dong 2(i)-ga' },
          '11140147': { ko: '쌍림동', en: 'Ssangnim-dong' },
          '11140148': { ko: '을지로6가', en: 'Euljiro 6(yuk)-ga' },
          '11140149': { ko: '을지로7가', en: 'Euljiro 7(chil)-ga' },
          '11140150': { ko: '을지로4가', en: 'Euljiro 4(sa)-ga' },
          '11140151': { ko: '을지로5가', en: 'Euljiro 5(o)-ga' },
          '11140152': { ko: '주교동', en: 'Jugyo-dong' },
          '11140153': { ko: '방산동', en: 'Bangsan-dong' },
          '11140154': { ko: '오장동', en: 'Ojang-dong' },
          '11140155': { ko: '을지로3가', en: 'Euljiro 3(sam)-ga' },
          '11140156': { ko: '입정동', en: 'Ipjeong-dong' },
          '11140157': { ko: '산림동', en: 'Sallim-dong' },
          '11140158': { ko: '충무로3가', en: 'Chungmuro 3(sam)-ga' },
          '11140159': { ko: '초동', en: 'Cho-dong' },
          '11140160': { ko: '인현동1가', en: 'Inhyeon-dong 1(il)-ga' },
          '11140161': { ko: '저동2가', en: 'Jeo-dong 2(i)-ga' },
          '11140162': { ko: '신당동', en: 'Sindang-dong' },
          '11140163': { ko: '흥인동', en: 'Heungin-dong' },
          '11140164': { ko: '무학동', en: 'Muhak-dong' },
          '11140165': { ko: '황학동', en: 'Hwanghak-dong' },
          '11140166': { ko: '서소문동', en: 'Seosomun-dong' },
          '11140167': { ko: '정동', en: 'Jeong-dong' },
          '11140168': { ko: '순화동', en: 'Sunhwa-dong' },
          '11140169': { ko: '의주로1가', en: 'Uijuro 1(il)-ga' },
          '11140170': { ko: '충정로1가', en: 'Chungjeongno 1(il)-ga' },
          '11140171': { ko: '중림동', en: 'Jungnim-dong' },
          '11140172': { ko: '의주로2가', en: 'Uijuro 2(i)-ga' },
          '11140173': { ko: '만리동1가', en: 'Malli-dong 1(il)-ga' },
          '11140174': { ko: '만리동2가', en: 'Malli-dong 2(i)-ga' },
        },
      },
      '11170000': {
        ko: '용산구',
        en: 'Yongsan-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11170101': { ko: '후암동', en: 'Huam-dong' },
          '11170102': { ko: '용산동2가', en: 'yongsan-dong 2(i)-ga' },
          '11170103': { ko: '용산동4가', en: 'Yongsan-dong 4(sa)-ga' },
          '11170104': { ko: '갈월동', en: 'Garwol-dong' },
          '11170105': { ko: '남영동', en: 'Namyeong-dong' },
          '11170106': { ko: '용산동1가', en: 'Yongsan-dong 1(il)-ga' },
          '11170107': { ko: '동자동', en: 'Dongja-dong' },
          '11170108': { ko: '서계동', en: 'Seogye-dong' },
          '11170109': { ko: '청파동1가', en: 'Cheongpa-dong 1(il)-ga' },
          '11170110': { ko: '청파동2가', en: 'Cheongpa-dong 2(i)-ga' },
          '11170111': { ko: '청파동3가', en: 'Cheongpa-dong 3(sam)-ga' },
          '11170112': { ko: '원효로1가', en: 'Wonhyoro 1(iI)-ga' },
          '11170113': { ko: '원효로2가', en: 'Wonhyoro 2(i)-ga' },
          '11170114': { ko: '신창동', en: 'Sinchang-dong' },
          '11170115': { ko: '산천동', en: 'Sancheon-dong' },
          '11170116': { ko: '청암동', en: 'Cheongam-dong' },
          '11170117': { ko: '원효로3가', en: 'Wonhyoro 3(sam)-ga' },
          '11170118': { ko: '원효로4가', en: 'Wonhyoro 4(sa)-ga' },
          '11170119': { ko: '효창동', en: 'Hyochang-dong' },
          '11170120': { ko: '도원동', en: 'Dowon-dong' },
          '11170121': { ko: '용문동', en: 'Yongmun-dong' },
          '11170122': { ko: '문배동', en: 'Munbae-dong' },
          '11170123': { ko: '신계동', en: 'Singye-dong' },
          '11170124': { ko: '한강로1가', en: 'Hangangno 1(il)-ga' },
          '11170125': { ko: '한강로2가', en: 'Hangangno 2(i)-ga' },
          '11170126': { ko: '용산동3가', en: 'Yongsan-dong 3(sam)-ga' },
          '11170127': { ko: '용산동5가', en: 'Yongsan-dong 5(o)-ga' },
          '11170128': { ko: '한강로3가', en: 'Hangangno 3(sam)-ga' },
          '11170129': { ko: '이촌동', en: 'Ichon-dong' },
          '11170130': { ko: '이태원동', en: 'Itaewon-dong' },
          '11170131': { ko: '한남동', en: 'Hannam-dong' },
          '11170132': { ko: '동빙고동', en: 'Dongbinggo-dong' },
          '11170133': { ko: '서빙고동', en: 'Seobinggo-dong' },
          '11170134': { ko: '주성동', en: 'Juseong-dong' },
          '11170135': { ko: '용산동6가', en: 'Yongsan-dong 6(yuk)-ga' },
          '11170136': { ko: '보광동', en: 'Bogwang-dong' },
        },
      },
      '11200000': {
        ko: '성동구',
        en: 'Seongdong-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11200101': { ko: '상왕십리동', en: 'Sangwangsimni-dong' },
          '11200102': { ko: '하왕십리동', en: 'Hawangsimni-dong' },
          '11200103': { ko: '홍익동', en: 'Hongik-dong' },
          '11200104': { ko: '도선동', en: 'Doseon-dong' },
          '11200105': { ko: '마장동', en: 'Majang-dong' },
          '11200106': { ko: '사근동', en: 'Sageun-dong' },
          '11200107': { ko: '행당동', en: 'Haengdang-dong' },
          '11200108': { ko: '응봉동', en: 'Eungbong-dong' },
          '11200109': { ko: '금호동1가', en: 'Geumho-dong1(il)-ga' },
          '11200110': { ko: '금호동2가', en: 'Geumho-dong2(i)-ga' },
          '11200111': { ko: '금호동3가', en: 'Geumho-dong3(sam)-ga' },
          '11200112': { ko: '금호동4가', en: 'Geumho-dong4(sa)-ga' },
          '11200113': { ko: '옥수동', en: 'Oksu-dong' },
          '11200114': { ko: '성수동1가', en: 'Seongsu-dong1(il)-ga' },
          '11200115': { ko: '성수동2가', en: 'Seongsu-dong2(i)-ga' },
          '11200118': { ko: '송정동', en: 'Songjeong-dong' },
          '11200122': { ko: '용답동', en: 'Yongdap-dong' },
        },
      },
      '11215000': {
        ko: '광진구',
        en: 'Gwangjin-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11215101': { ko: '중곡동', en: 'Junggok-dong' },
          '11215102': { ko: '능동', en: 'Neung-dong' },
          '11215103': { ko: '구의동', en: 'Guui-dong' },
          '11215104': { ko: '광장동', en: 'Gwangjang-dong' },
          '11215105': { ko: '자양동', en: 'Jayang-dong' },
          '11215107': { ko: '화양동', en: 'Hwayang-dong' },
          '11215109': { ko: '군자동', en: 'Gunja-dong' },
        },
      },
      '11230000': {
        ko: '동대문구',
        en: 'Dongdaemun-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11230101': { ko: '신설동', en: 'Sinseol-dong' },
          '11230102': { ko: '용두동', en: 'Yongdu-dong' },
          '11230103': { ko: '제기동', en: 'Jegi-dong' },
          '11230104': { ko: '전농동', en: 'Jeonnong-dong' },
          '11230105': { ko: '답십리동', en: 'Dapsipni-dong' },
          '11230106': { ko: '장안동', en: 'Jangan-dong' },
          '11230107': { ko: '청량리동', en: 'Cheongnyangni-dong' },
          '11230108': { ko: '회기동', en: 'Hoegi-dong' },
          '11230109': { ko: '휘경동', en: 'Hwigyeong-dong' },
          '11230110': { ko: '이문동', en: 'Imun-dong' },
        },
      },
      '11260000': {
        ko: '중랑구',
        en: 'Jungnang-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11260101': { ko: '면목동', en: 'Myeonmok-dong' },
          '11260102': { ko: '상봉동', en: 'Sangbong-dong' },
          '11260103': { ko: '중화동', en: 'Junghwa-dong' },
          '11260104': { ko: '묵동', en: 'Muk-dong' },
          '11260105': { ko: '망우동', en: 'Mangu-dong' },
          '11260106': { ko: '신내동', en: 'Sinnae-dong' },
        },
      },
      '11290000': {
        ko: '성북구',
        en: 'Seongbuk-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11290101': { ko: '성북동', en: 'Seongbuk-dong' },
          '11290102': { ko: '성북동1가', en: 'Seongbuk-dong 1(il)-ga' },
          '11290103': { ko: '돈암동', en: 'Donam-dong' },
          '11290104': { ko: '동소문동1가', en: 'Dongsomun-dong 1(il)-ga' },
          '11290105': { ko: '동소문동2가', en: 'Dongsomun-dong 2(i)-ga' },
          '11290106': { ko: '동소문동3가', en: 'Dongsomun-dong 3(sam)-ga' },
          '11290107': { ko: '동소문동4가', en: 'Dongsomun-dong 4(sa)-ga' },
          '11290108': { ko: '동소문동5가', en: 'Dongsomun-dong 5(o)-ga' },
          '11290109': { ko: '동소문동6가', en: 'Dongsomun-dong 6(yuk)-ga' },
          '11290110': { ko: '동소문동7가', en: 'Dongsomun-dong 7(chil)-ga' },
          '11290111': { ko: '삼선동1가', en: 'Samseon-dong 1(il)-ga' },
          '11290112': { ko: '삼선동2가', en: 'Samseon-dong 2(i)-ga' },
          '11290113': { ko: '삼선동3가', en: 'Samseon-dong 3(sam)-ga' },
          '11290114': { ko: '삼선동4가', en: 'Samseon-dong 4(sa)-ga' },
          '11290115': { ko: '삼선동5가', en: 'Samseon-dong 5(o)-ga' },
          '11290116': { ko: '동선동1가', en: 'Dongseon-dong 1(il)-ga' },
          '11290117': { ko: '동선동2가', en: 'Dongseon-dong 2(i)-ga' },
          '11290118': { ko: '동선동3가', en: 'Dongseon-dong 3(sam)-ga' },
          '11290119': { ko: '동선동4가', en: 'Dongseon-dong 4(sa)-ga' },
          '11290120': { ko: '동선동5가', en: 'Dongseon-dong 5(o)-ga' },
          '11290121': { ko: '안암동1가', en: 'Anam-dong 1(il)-ga' },
          '11290122': { ko: '안암동2가', en: 'Anam-dong 2(i)-ga' },
          '11290123': { ko: '안암동3가', en: 'Anam-dong 3(sam)-ga' },
          '11290124': { ko: '안암동4가', en: 'Anam-dong 4(sa)-ga' },
          '11290125': { ko: '안암동5가', en: 'Anam-dong 5(o)-ga' },
          '11290126': { ko: '보문동4가', en: 'Bomun-dong 4(sa)-ga' },
          '11290127': { ko: '보문동5가', en: 'Bomun-dong 5(o)-ga' },
          '11290128': { ko: '보문동6가', en: 'Bomun-dong 6(yuk)-ga' },
          '11290129': { ko: '보문동7가', en: 'Bomun-dong 7(chil)-ga' },
          '11290130': { ko: '보문동1가', en: 'Bomun-dong 1(il)-ga' },
          '11290131': { ko: '보문동2가', en: 'Bomun-dong 2(i)-ga' },
          '11290132': { ko: '보문동3가', en: 'Bomun-dong 3(sam)-ga' },
          '11290133': { ko: '정릉동', en: 'Jeongneung-dong' },
          '11290134': { ko: '길음동', en: 'Gireum-dong' },
          '11290135': { ko: '종암동', en: 'Jongam-dong' },
          '11290136': { ko: '하월곡동', en: 'Hawolgok-dong' },
          '11290137': { ko: '상월곡동', en: 'Sangwolgok-dong' },
          '11290138': { ko: '장위동', en: 'Jangwi-dong' },
          '11290139': { ko: '석관동', en: 'Seokgwan-dong' },
        },
      },
      '11305000': {
        ko: '강북구',
        en: 'Gangbuk-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11305101': { ko: '미아동', en: 'Mia-dong' },
          '11305102': { ko: '번동', en: 'Beon-dong' },
          '11305103': { ko: '수유동', en: 'Suyu-dong' },
          '11305104': { ko: '우이동', en: 'Ui-dong' },
        },
      },
      '11320000': {
        ko: '도봉구',
        en: 'Dobong-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11320105': { ko: '쌍문동', en: 'Ssangmun-dong' },
          '11320106': { ko: '방학동', en: 'Banghak-dong' },
          '11320107': { ko: '창동', en: 'Chang-dong' },
          '11320108': { ko: '도봉동', en: 'Dobong-dong' },
        },
      },
      '11350000': {
        ko: '노원구',
        en: 'Nowon-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11350102': { ko: '월계동', en: 'Wolgye-dong' },
          '11350103': { ko: '공릉동', en: 'Gongneung-dong' },
          '11350104': { ko: '하계동', en: 'Hagye-dong' },
          '11350105': { ko: '상계동', en: 'Sanggye-dong' },
          '11350106': { ko: '중계동', en: 'Junggye-dong' },
        },
      },
      '11380000': {
        ko: '은평구',
        en: 'Eunpyeong-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11380101': { ko: '수색동', en: 'Susaek-dong' },
          '11380102': { ko: '녹번동', en: 'Nokbeon-dong' },
          '11380103': { ko: '불광동', en: 'Bulgwang-dong' },
          '11380104': { ko: '갈현동', en: 'Galhyeon-dong' },
          '11380105': { ko: '구산동', en: 'Gusan-dong' },
          '11380106': { ko: '대조동', en: 'Daejo-dong' },
          '11380107': { ko: '응암동', en: 'Eungam-dong' },
          '11380108': { ko: '역촌동', en: 'Yeokchon-dong' },
          '11380109': { ko: '신사동', en: 'Sinsa-dong' },
          '11380110': { ko: '증산동', en: 'Jeungsan-dong' },
          '11380114': { ko: '진관동', en: 'Jingwan-dong' },
        },
      },
      '11410000': {
        ko: '서대문구',
        en: 'Seodaemun-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11410101': { ko: '충정로2가', en: 'Chungjeongno 2(i)-ga' },
          '11410102': { ko: '충정로3가', en: 'Chungjeongno 3(sam)-ga' },
          '11410103': { ko: '합동', en: 'Hap-dong' },
          '11410104': { ko: '미근동', en: 'Migeun-dong' },
          '11410105': { ko: '냉천동', en: 'Naengcheon-dong' },
          '11410106': { ko: '천연동', en: 'Cheonyeon-dong' },
          '11410107': { ko: '옥천동', en: 'Okcheon-dong' },
          '11410108': { ko: '영천동', en: 'Yeongcheon-dong' },
          '11410109': { ko: '현저동', en: 'Hyeonjeo-dong' },
          '11410110': { ko: '북아현동', en: 'Bugahyeon-dong' },
          '11410111': { ko: '홍제동', en: 'Hongje-dong' },
          '11410112': { ko: '대현동', en: 'Daehyeon-dong' },
          '11410113': { ko: '대신동', en: 'Daesin-dong' },
          '11410114': { ko: '신촌동', en: 'Sinchon-dong' },
          '11410115': { ko: '봉원동', en: 'Bongwon-dong' },
          '11410116': { ko: '창천동', en: 'Changcheon-dong' },
          '11410117': { ko: '연희동', en: 'Yeonhui-dong' },
          '11410118': { ko: '홍은동', en: 'Hongeun-dong' },
          '11410119': { ko: '북가좌동', en: 'Bukgajwa-dong' },
          '11410120': { ko: '남가좌동', en: 'Namgajwa-dong' },
        },
      },
      '11440000': {
        ko: '마포구',
        en: 'Mapo-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11440101': { ko: '아현동', en: 'Ahyeon-dong' },
          '11440102': { ko: '공덕동', en: 'Gongdeok-dong' },
          '11440103': { ko: '신공덕동', en: 'Singongdeok-dong' },
          '11440104': { ko: '도화동', en: 'Dohwa-dong' },
          '11440105': { ko: '용강동', en: 'Yonggang-dong' },
          '11440106': { ko: '토정동', en: 'Tojeong-dong' },
          '11440107': { ko: '마포동', en: 'Mapo-dong' },
          '11440108': { ko: '대흥동', en: 'Daeheung-dong' },
          '11440109': { ko: '염리동', en: 'Yeomni-dong' },
          '11440110': { ko: '노고산동', en: 'Nogosan-dong' },
          '11440111': { ko: '신수동', en: 'Sinsu-dong' },
          '11440112': { ko: '현석동', en: 'Hyeonseok-dong' },
          '11440113': { ko: '구수동', en: 'Gusu-dong' },
          '11440114': { ko: '창전동', en: 'Changjeon-dong' },
          '11440115': { ko: '상수동', en: 'Sangsu-dong' },
          '11440116': { ko: '하중동', en: 'Hajung-dong' },
          '11440117': { ko: '신정동', en: 'Sinjeong-dong' },
          '11440118': { ko: '당인동', en: 'Dangin-dong' },
          '11440120': { ko: '서교동', en: 'Seogyo-dong' },
          '11440121': { ko: '동교동', en: 'Donggyo-dong' },
          '11440122': { ko: '합정동', en: 'Hapjeong-dong' },
          '11440123': { ko: '망원동', en: 'Mangwon-dong' },
          '11440124': { ko: '연남동', en: 'Yeonnam-dong' },
          '11440125': { ko: '성산동', en: 'Seongsan-dong' },
          '11440126': { ko: '중동', en: 'Jung-dong' },
          '11440127': { ko: '상암동', en: 'Sangam-dong' },
        },
      },
      '11470000': {
        ko: '양천구',
        en: 'Yangcheon-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11470101': { ko: '신정동', en: 'Sinjeong-dong' },
          '11470102': { ko: '목동', en: 'Mok-dong' },
          '11470103': { ko: '신월동', en: 'Sinwol-dong' },
        },
      },
      '11500000': {
        ko: '강서구',
        en: 'Gangseo-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11500101': { ko: '염창동', en: 'Yeomchang-dong' },
          '11500102': { ko: '등촌동', en: 'Deungchon-dong' },
          '11500103': { ko: '화곡동', en: 'Hwagok-dong' },
          '11500104': { ko: '가양동', en: 'Gayang-dong' },
          '11500105': { ko: '마곡동', en: 'Magok-dong' },
          '11500106': { ko: '내발산동', en: 'Naebalsan-dong' },
          '11500107': { ko: '외발산동', en: 'Oebalsan-dong' },
          '11500108': { ko: '공항동', en: 'Gonghang-dong' },
          '11500109': { ko: '방화동', en: 'Banghwa-dong' },
          '11500110': { ko: '개화동', en: 'Gaehwa-dong' },
          '11500111': { ko: '과해동', en: 'Gwahae-dong' },
          '11500112': { ko: '오곡동', en: 'Ogok-dong' },
          '11500113': { ko: '오쇠동', en: 'Osoe-dong' },
        },
      },
      '11530000': {
        ko: '구로구',
        en: 'Guro-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11530101': { ko: '신도림동', en: 'Sindorim-dong' },
          '11530102': { ko: '구로동', en: 'Guro-dong' },
          '11530103': { ko: '가리봉동', en: 'Garibong-dong' },
          '11530106': { ko: '고척동', en: 'Gocheok-dong' },
          '11530107': { ko: '개봉동', en: 'Gaebong-dong' },
          '11530108': { ko: '오류동', en: 'Oryu-dong' },
          '11530109': { ko: '궁동', en: 'Gung-dong' },
          '11530110': { ko: '온수동', en: 'Onsu-dong' },
          '11530111': { ko: '천왕동', en: 'Cheonwang-dong' },
          '11530112': { ko: '항동', en: 'Hang-dong' },
        },
      },
      '11545000': {
        ko: '금천구',
        en: 'Geumcheon-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11545101': { ko: '가산동', en: 'Gasan-dong' },
          '11545102': { ko: '독산동', en: 'Doksan-dong' },
          '11545103': { ko: '시흥동', en: 'Siheung-dong' },
        },
      },
      '11560000': {
        ko: '영등포구',
        en: 'Yeongdeungpo-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11560101': { ko: '영등포동', en: 'Yeongdeungpo-dong' },
          '11560102': { ko: '영등포동1가', en: 'Yeongdeungpo-dong 1(il)-ga' },
          '11560103': { ko: '영등포동2가', en: 'Yeongdeungpo-dong 2(i)-ga' },
          '11560104': { ko: '영등포동3가', en: 'Yeongdeungpo-dong 3(sam)-ga' },
          '11560105': { ko: '영등포동4가', en: 'Yeongdeungpo-dong 4(sa)-ga' },
          '11560106': { ko: '영등포동5가', en: 'Yeongdeungpo-dong 5(o)-ga' },
          '11560107': { ko: '영등포동6가', en: 'Yeongdeungpo-dong 6(yuk)-ga' },
          '11560108': { ko: '영등포동7가', en: 'Yeongdeungpo-dong 7(chil)-ga' },
          '11560109': { ko: '영등포동8가', en: 'Yeongdeungpo-dong 8(pal)-ga' },
          '11560110': { ko: '여의도동', en: 'Yeouido-dong' },
          '11560111': { ko: '당산동1가', en: 'Dangsan-dong 1(il)-ga' },
          '11560112': { ko: '당산동2가', en: 'Dangsan-dong 2(i)-ga' },
          '11560113': { ko: '당산동3가', en: 'Dangsan-dong 3(sam)-ga' },
          '11560114': { ko: '당산동4가', en: 'Dangsan-dong 4(sa)-ga' },
          '11560115': { ko: '당산동5가', en: 'Dangsan-dong 5(o)-ga' },
          '11560116': { ko: '당산동6가', en: 'Dangsan-dong 6(yuk)-ga' },
          '11560117': { ko: '당산동', en: 'Dangsan-dong' },
          '11560118': { ko: '도림동', en: 'Dorim-dong' },
          '11560119': { ko: '문래동1가', en: 'Mullae-dong 1(il)-ga' },
          '11560120': { ko: '문래동2가', en: 'Mullae-dong 2(i)-ga' },
          '11560121': { ko: '문래동3가', en: 'Mullae-dong 3(sam)-ga' },
          '11560122': { ko: '문래동4가', en: 'Mullae-dong 4(sa)-ga' },
          '11560123': { ko: '문래동5가', en: 'Mullae-dong 5(o)-ga' },
          '11560124': { ko: '문래동6가', en: 'Mullae-dong 6(yuk)-ga' },
          '11560125': { ko: '양평동1가', en: 'Yangpyeong-dong 1(il)-ga' },
          '11560126': { ko: '양평동2가', en: 'Yangpyeong-dong 2(i)-ga' },
          '11560127': { ko: '양평동3가', en: 'Yangpyeong-dong 3(sam)-ga' },
          '11560128': { ko: '양평동4가', en: 'Yangpyeong-dong 4(sa)-ga' },
          '11560129': { ko: '양평동5가', en: 'Yangpyeong-dong 5(o)-ga' },
          '11560130': { ko: '양평동6가', en: 'Yangpyeong-dong 6(yuk)-ga' },
          '11560131': { ko: '양화동', en: 'Yanghwa-dong' },
          '11560132': { ko: '신길동', en: 'Singil-dong' },
          '11560133': { ko: '대림동', en: 'Daerim-dong' },
          '11560134': { ko: '양평동', en: 'Yangpyeong-dong' },
        },
      },
      '11590000': {
        ko: '동작구',
        en: 'Dongjak-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11590101': { ko: '노량진동', en: 'Noryangjin-dong' },
          '11590102': { ko: '상도동', en: 'Sangdo-dong' },
          '11590103': { ko: '상도1동', en: 'Sangdo 1(il)-dong' },
          '11590104': { ko: '본동', en: 'Bon-dong' },
          '11590105': { ko: '흑석동', en: 'Heukseok-dong' },
          '11590106': { ko: '동작동', en: 'Dongjak-dong' },
          '11590107': { ko: '사당동', en: 'Sadang-dong' },
          '11590108': { ko: '대방동', en: 'Daebang-dong' },
          '11590109': { ko: '신대방동', en: 'Sindaebang-dong' },
        },
      },
      '11620000': {
        ko: '관악구',
        en: 'Gwanak-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11620101': { ko: '봉천동', en: 'Bongcheon-dong' },
          '11620102': { ko: '신림동', en: 'Sillim-dong' },
          '11620103': { ko: '남현동', en: 'Namhyeon-dong' },
        },
      },
      '11650000': {
        ko: '서초구',
        en: 'Seocho-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11650101': { ko: '방배동', en: 'Bangbae-dong' },
          '11650102': { ko: '양재동', en: 'Yangjae-dong' },
          '11650103': { ko: '우면동', en: 'Umyeon-dong' },
          '11650104': { ko: '원지동', en: 'Wonji-dong' },
          '11650106': { ko: '잠원동', en: 'Jamwon-dong' },
          '11650107': { ko: '반포동', en: 'Banpo-dong' },
          '11650108': { ko: '서초동', en: 'Seocho-dong' },
          '11650109': { ko: '내곡동', en: 'Naegok-dong' },
          '11650110': { ko: '염곡동', en: 'Yeomgok-dong' },
          '11650111': { ko: '신원동', en: 'Sinwon-dong' },
        },
      },
      '11680000': {
        ko: '강남구',
        en: 'Gangnam-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11680101': { ko: '역삼동', en: 'Yeoksam-dong' },
          '11680103': { ko: '개포동', en: 'Gaepo-dong' },
          '11680104': { ko: '청담동', en: 'Cheongdam-dong' },
          '11680105': { ko: '삼성동', en: 'Samseong-dong' },
          '11680106': { ko: '대치동', en: 'Daechi-dong' },
          '11680107': { ko: '신사동', en: 'Sinsa-dong' },
          '11680108': { ko: '논현동', en: 'Nonhyeon-dong' },
          '11680110': { ko: '압구정동', en: 'Apgujeong-dong' },
          '11680111': { ko: '세곡동', en: 'Segok-dong' },
          '11680112': { ko: '자곡동', en: 'Jagok-dong' },
          '11680113': { ko: '율현동', en: 'Yulhyeon-dong' },
          '11680114': { ko: '일원동', en: 'Irwon-dong' },
          '11680115': { ko: '수서동', en: 'Suseo-dong' },
          '11680118': { ko: '도곡동', en: 'Dogok-dong' },
        },
      },
      '11710000': {
        ko: '송파구',
        en: 'Songpa-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11710101': { ko: '잠실동', en: 'Jamsil-dong' },
          '11710102': { ko: '신천동', en: 'Sincheon-dong' },
          '11710103': { ko: '풍납동', en: 'Pungnap-dong' },
          '11710104': { ko: '송파동', en: 'Songpa-dong' },
          '11710105': { ko: '석촌동', en: 'Seokchon-dong' },
          '11710106': { ko: '삼전동', en: 'Samjeon-dong' },
          '11710107': { ko: '가락동', en: 'Garak-dong' },
          '11710108': { ko: '문정동', en: 'Munjeong-dong' },
          '11710109': { ko: '장지동', en: 'Jangji-dong' },
          '11710111': { ko: '방이동', en: 'Bangi-dong' },
          '11710112': { ko: '오금동', en: 'Ogeum-dong' },
          '11710113': { ko: '거여동', en: 'Geoyeo-dong' },
          '11710114': { ko: '마천동', en: 'Macheon-dong' },
        },
      },
      '11740000': {
        ko: '강동구',
        en: 'Gangdong-gu',
        children: {
          '0': { ko: '전체', en: 'All' },
          '11740101': { ko: '명일동', en: 'Myeongil-dong' },
          '11740102': { ko: '고덕동', en: 'Godeok-dong' },
          '11740103': { ko: '상일동', en: 'Sangil-dong' },
          '11740105': { ko: '길동', en: 'Gil-dong' },
          '11740106': { ko: '둔촌동', en: 'Dunchon-dong' },
          '11740107': { ko: '암사동', en: 'Amsa-dong' },
          '11740108': { ko: '성내동', en: 'Seongnae-dong' },
          '11740109': { ko: '천호동', en: 'Cheonho-dong' },
          '11740110': { ko: '강일동', en: 'Gangil-dong' },
        },
      },
    },
  },
} satisfies TLocationDataType;
