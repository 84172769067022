import styled from 'styled-components';

type TProps = {
  locationList: {
    id: string;
    name: string;
  }[];
  onSelectItem: (id: string) => void;
  selectedId?: string;
  isBorder?: boolean;
};

const LocationSelectorItem = ({ locationList, selectedId, onSelectItem, isBorder = true }: TProps) => {
  const handleItemSelect = (id: string) => () => {
    onSelectItem(id);
  };

  return (
    <Container $isBorder={isBorder}>
      <List>
        {locationList.map((item) => (
          <Item key={item.id} $isActive={item.id === selectedId} onClick={handleItemSelect(item.id)}>
            {item.name}
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default LocationSelectorItem;

const Container = styled.div<{ $isBorder: boolean }>`
  width: 100%;
  height: 100%;
  padding-right: ${({ $isBorder }) => ($isBorder ? '5px' : 0)};
  border-right: ${({ theme, $isBorder }) => ($isBorder ? `1px solid ${theme.newColors.dark3}` : 'none')};
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.newColors.grey1};
    border-radius: 5px;
  }
`;

const Item = styled.li<{ $isActive: boolean }>`
  width: 100%;
  color: ${({ theme, $isActive }) => ($isActive ? theme.newColors.primary : theme.newColors.white)};
  cursor: pointer;
`;
