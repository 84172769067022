import { ScatterplotLayer } from '@deck.gl/layers/typed';
import { hexToRGBArray } from '@utils/hexToRGBA';
import { INetworkData } from 'interfaces/networkInterface';

type Props = {
  id: string;
  data: INetworkData[];
  onClick: (data: INetworkData) => void;
};

const MonitoringNetworkLayer = ({ id, data, onClick }: Props) => {
  return new ScatterplotLayer({
    id,
    data,
    pickable: true,
    getPosition: (d) => d.coordinates,
    getFillColor: (d) => hexToRGBArray(d.color) as [number, number, number],
    getLineColor: (d) => [...hexToRGBArray(d.color), 100] as [number, number, number, number],
    lineWidthMinPixels: 4,
    radiusMaxPixels: 100,
    radiusMinPixels: 10,
    radiusScale: 6,
    stroked: true,
    onClick: (info) => onClick(info.object),
  });
};

export default MonitoringNetworkLayer;
