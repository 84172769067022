import styled from 'styled-components';
import { ReactComponent as ChevronLeftIcon } from '@assets/icons/chevron_left_icon.svg';
import { ReactComponent as ChevronRightIcon } from '@assets/icons/chevron_right_icon.svg';
import { useCallback, useState } from 'react';
import { monthData } from '@static/calendarData';
import dayjs from 'dayjs';
import ConfirmButton from '@components/common/ConfirmButton';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const MIN_YEAR = 2020;
const MAX_YEAR = 2022;
// string을 Dayjs 타입으로 올바르게 변환하려면 구분자로 '.' 대신 '-'을 사용해야 함.
const DATE_FORMAT_STRING = 'YYYY-MM';

type YearChangeDirection = 'left' | 'right';
type Props = {
  startDateInputName: string;
  endDateInputName: string;
  onCloseHandler: () => void;
};

const CalendarBody = ({ startDateInputName, endDateInputName, onCloseHandler }: Props) => {
  const { setValue, getValues } = useFormContext();
  const [year, setYear] = useState(getValues(startDateInputName) !== '' ? Number(getValues(startDateInputName).slice(0, 4)) : 2020);
  const [startDate, setStartDate] = useState(getValues(startDateInputName) === '' ? undefined : dayjs(getValues(startDateInputName)));
  const [endDate, setEndDate] = useState(getValues(endDateInputName) === '' ? undefined : dayjs(getValues(endDateInputName)));
  const [hoverValue, setHoverValue] = useState<number | undefined>();
  const { t } = useTranslation();

  const handleConfirmClick = () => {
    if (!startDate || !endDate) return;
    setValue(startDateInputName, startDate.format(DATE_FORMAT_STRING), { shouldValidate: true });
    setValue(endDateInputName, endDate.format(DATE_FORMAT_STRING), { shouldValidate: true });
    onCloseHandler();
  };

  const handleYearChange = (direction: YearChangeDirection) => {
    const changedYear = direction === 'left' ? year - 1 : year + 1;
    setYear(changedYear < MIN_YEAR ? MIN_YEAR : changedYear > MAX_YEAR ? MAX_YEAR : changedYear);
  };

  const handleMonthClick = (value: number) => {
    const selectedDate = dayjs(`${year}-${value}`);

    if (!startDate) {
      setStartDate(selectedDate);
    } else if (!endDate) {
      selectedDate.diff(startDate, 'month') >= 0 ? setEndDate(selectedDate) : setStartDate(selectedDate);
    } else {
      setStartDate(selectedDate);
      setEndDate(undefined);
    }
  };

  const checkMonthButtonActive = useCallback(
    (value: number) => {
      const currentDate = dayjs(`${year}-${value}`);
      const hoverDate = hoverValue ? dayjs(`${year}-${hoverValue}`) : undefined;

      if (!startDate) return false;
      if (startDate && !endDate) {
        if (currentDate.diff(startDate, 'month') <= 0 || !hoverDate) return currentDate.diff(startDate, 'month') === 0 ? true : false;
        else return currentDate.diff(startDate, 'month') >= 0 && currentDate.diff(hoverDate, 'month') <= 0 ? true : false;
      }
      return currentDate.diff(startDate, 'month') >= 0 && currentDate.diff(endDate, 'month') <= 0;
    },
    [startDate, endDate, year, hoverValue],
  );

  const checkConfirmActive = useCallback(() => {
    return startDate && endDate;
  }, [startDate, endDate]);

  const handleHoverDate = (value: number | undefined) => {
    if (value) {
      setHoverValue(value);
    } else {
      setHoverValue(undefined);
    }
  };

  return (
    <Container>
      <YearContainer>
        <ArrowButton type="button" onClick={() => handleYearChange('left')}>
          <ChevronLeftIcon />
        </ArrowButton>
        <span className="year">{year}</span>
        <ArrowButton type="button" onClick={() => handleYearChange('right')}>
          <ChevronRightIcon />
        </ArrowButton>
      </YearContainer>
      <MonthContainer>
        {monthData.map((month) => (
          <MonthButton
            key={month.value}
            type="button"
            $isActive={checkMonthButtonActive(month.value)}
            onClick={() => handleMonthClick(month.value)}
            onMouseEnter={() => handleHoverDate(month.value)}
            onMouseLeave={() => handleHoverDate(undefined)}
          >
            {month.name.slice(0, 3)}
          </MonthButton>
        ))}
      </MonthContainer>
      <ConfirmButton
        type="button"
        text={t('common.confirm')}
        onClick={handleConfirmClick}
        isHoveredColor={false}
        disabled={!checkConfirmActive()}
      />
    </Container>
  );
};

export default CalendarBody;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
`;

const YearContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;
  color: ${({ theme }) => theme.newColors.white};

  span.year {
    display: flex;
    justify-content: center;
    width: 40px;
  }
`;

const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 30px;
`;

const MonthContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  padding: 0 11px;
  margin: 22px 0 30px 0;
`;

const MonthButton = styled.button<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  font-size: 20px;
  font-variation-settings: 'wght' 400;
  line-height: 30px;
  letter-spacing: -0.4px;
  color: ${({ theme, $isActive }) => ($isActive ? theme.newColors.primary : theme.newColors.grey1)};
`;
