import CalendarBody from '@components/common/selector/dateSelector/CalendarBody';
import hexToRGBA from '@utils/hexToRGBA';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  startDateInputName: string;
  endDateInputName: string;
  onCloseHandler: () => void;
  backgroundColor?: string;
};

const Calendar = ({ startDateInputName, endDateInputName, onCloseHandler, backgroundColor }: Props) => {
  const { t } = useTranslation();
  return (
    <Container $backgroundColor={backgroundColor}>
      <Header>
        <span>{t('calendar.choose')}</span>
      </Header>
      <CalendarBody startDateInputName={startDateInputName} endDateInputName={endDateInputName} onCloseHandler={onCloseHandler} />
    </Container>
  );
};

export default Calendar;

const Container = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  width: 350px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.newColors.dark1};
`;

const Header = styled.div`
  padding: 14px 20px;
  border-bottom: 1px solid ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.3)};
  font-size: 18px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;
  display: flex;
  justify-content: center;
`;
