import React from 'react';
import { DeckGLOverlay } from '../common/layer/DeckGLOverlay';
import MonitoringNetworkLayer from '@components/common/layer/MonitoringNetworkLayer';
import { networkData } from '@static/networkData';
import { INetworkData } from 'interfaces/networkInterface';

type Props = {
  onClick: (data: INetworkData) => void;
};

const NetworkLayer = ({ onClick }: Props) => {
  const layers = MonitoringNetworkLayer({ id: 'monitoring-network-layer', data: networkData, onClick });
  return <DeckGLOverlay layers={[layers]} interleaved={true} getCursor={({ isHovering }) => (isHovering ? 'pointer' : 'default')} />;
};

export default NetworkLayer;
