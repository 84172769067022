import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ControlPanel from './ControlPanel';
import { Map, MapRef, MapboxEvent, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapLayer from './MapLayer';
import ResultPanel from './resultPanel/ResultPanel';
import TimelapsePanel from './TimelapsePanel';
import useMapStore from 'stores/mapStore';
import ConditionPanel from '@components/map/conditionPanel/ConditionPanel';
import LocationPanel from '@components/map/locationPanel/LocationPanel';
import { useTotalCarbonNumQuery } from 'queries/useTotalCarbonNumQuery';
import { useMontlyCarbonQuery } from 'queries/useMontlyCarbonQuery';
import { useDistrictsQuery } from 'queries/useDistrictsQuery';
import { TPopupCarbonDataType } from 'interfaces/mapInterface';
import { Position } from '@turf/helpers';
import { TWidthType } from '@hooks/useWidthType';

type TProps = {
  widthType: TWidthType;
};

const Mapbox = ({ widthType }: TProps) => {
  const mapRef = useRef<MapRef>(null);
  const [popupData, setPopupData] = useState<{ id: number; data: TPopupCarbonDataType; position: Position }>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [mapViewState, setMapViewState] = useState<ViewState>({
    latitude: 37.55032176270455,
    longitude: 126.96716219186783,
    zoom: widthType === 'mobile' ? 9 : 10,
    bearing: 0,
    pitch: 0,
    padding: { bottom: 0, left: 0, right: 0, top: 0 },
  });

  const { selectDataCondition, activeYearMonth } = useMapStore();
  const { data } = useTotalCarbonNumQuery(selectDataCondition, !!selectDataCondition);
  const { data: monthlyData } = useMontlyCarbonQuery(
    { ctp_cd: selectDataCondition.location.ctp_cd, dataType: selectDataCondition.dataType, yearMonth: activeYearMonth },
    !!activeYearMonth,
  );
  const { data: districtsData } = useDistrictsQuery(selectDataCondition.location.ctp_cd, !!selectDataCondition.location.ctp_cd);

  const onMapLoadHandler = (e: MapboxEvent) => {
    setIsLoaded(true);
  };

  const onMapMoveHandler = (e: ViewStateChangeEvent) => {
    setMapViewState(e.viewState);
  };

  const onPopupClose = useCallback(() => {
    setPopupData(undefined);
    setShowPopup(false);
  }, []);

  useEffect(() => {
    if (popupData) setShowPopup(true);
  }, [popupData]);

  useEffect(() => {
    onPopupClose();
  }, [selectDataCondition, activeYearMonth]);

  useEffect(() => {
    if (!mapRef.current || !widthType) return;

    if (popupData) {
      widthType === 'mobile' && mapRef.current?.flyTo({ center: popupData.position as [number, number], duration: 0, offset: [-160, 40] });
      setShowPopup(true);
    }
  }, [popupData, widthType]);

  return (
    <Container>
      <Map
        {...mapViewState}
        ref={mapRef}
        mapStyle={process.env.REACT_APP_DARK_STYLE}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY}
        onLoad={onMapLoadHandler}
        onMove={onMapMoveHandler}
        pitchWithRotate={false}
        dragRotate={false}
        renderWorldCopies={false}
        projection={{ name: 'mercator' }}
        maxBounds={[116.10206668370057, 29.86610147826427, 143.73154799335805, 44.52697846134734]}
      >
        {mapRef.current && isLoaded && (
          <>
            <ConditionPanel widthType={widthType} />
            <ControlPanel />
            <MapLayer
              ctp_cd={selectDataCondition.location.ctp_cd}
              dataType={selectDataCondition.dataType}
              data={monthlyData}
              districtsData={districtsData}
              selectedId={popupData?.id.toString()}
              onClick={setPopupData}
            />
            {data && <ResultPanel data={data} widthType={widthType} />}
            {selectDataCondition && activeYearMonth && <TimelapsePanel map={mapRef.current} widthType={widthType} />}
            {popupData && showPopup && <LocationPanel coordinate={popupData.position} onClose={onPopupClose} data={popupData.data} />}
          </>
        )}
      </Map>
    </Container>
  );
};

export default Mapbox;

const Container = styled.div`
  height: calc(100% - 60px);

  div.mapboxgl-map {
    div.mapboxgl-control-container {
      div.mapboxgl-ctrl-bottom-right div.mapboxgl-ctrl.mapboxgl-ctrl-group {
        background: none;
        box-shadow: none;
        margin: 0px 20px 10px 0px;

        & > button {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors.gray};
          box-shadow:
            1px 1px 1px 0px rgba(255, 255, 255, 0.3) inset,
            0px 3px 5px 0px rgba(0, 0, 0, 0.15);

          &[aria-disabled='false']:hover {
            background-color: ${({ theme }) => theme.colors.orange};
          }

          &.mapboxgl-ctrl-zoom-in {
            margin-bottom: 10px;
            span {
              background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikdyb3VwIDczMjciPgo8cGF0aCBpZD0iTGluZSA1MzEiIGQ9Ik0xIDguMDM4NTdIMTUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggaWQ9IkxpbmUgNTMyIiBkPSJNNy45NjE0MyAxTDcuOTYxNDMgMTUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9nPgo8L3N2Zz4K);
            }
          }
          &.mapboxgl-ctrl-zoom-out {
            span {
              background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDE2IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFIMTUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==);
            }
          }
        }

        & > button + button {
          border-top: none;
        }
      }

      ${({ theme }) =>
        theme.media.tablet(`
          div.mapboxgl-ctrl-bottom-left,
          div.mapboxgl-ctrl-bottom-right {
            bottom: 60px;
          }
      `)}

      ${({ theme }) =>
        theme.media.mobile(`
          div.mapboxgl-ctrl-bottom-right div.mapboxgl-ctrl.mapboxgl-ctrl-group {
            display: none;
          }
      `)}
    }
  }
`;
