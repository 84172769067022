import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  data: {
    title: string;
    content: string | { type?: string; text: string }[];
  };
  contentGap?: number;
  children: ReactNode;
};

const AboutItem = ({ data, contentGap, children }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t(data.title)}</Title>
      <Text>
        {typeof data.content === 'string' ? (
          <Trans i18nKey={t(data.content)} components={{ span: <span className="bold" /> }} />
        ) : (
          data.content.map((item, idx) => {
            if (item.type === 'bold') {
              return (
                <span key={idx} className={item.type}>
                  {t(item.text)}
                </span>
              );
            } else {
              return <React.Fragment key={idx}>{t(item.text)}</React.Fragment>;
            }
          })
        )}
      </Text>
      <Content $gap={contentGap}>{children}</Content>
    </Container>
  );
};

export default AboutItem;

const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.newColors.white};
  font-size: 35px;
  font-variation-settings: 'wght' 800;
  line-height: 44px; /* 166.667% */
  letter-spacing: -0.7px;

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 25px;
      line-height: 40px; /* 160% */
      letter-spacing: -0.5px;
  `)}
`;

const Text = styled.div`
  margin-top: 30px;

  color: ${({ theme }) => theme.newColors.white};
  font-size: 20px;
  font-variation-settings: 'wght' 400;
  line-height: 40px; /* 166.667% */
  letter-spacing: -0.4px;

  span.bold {
    color: ${({ theme }) => theme.newColors.primary};
    font-variation-settings: 'wght' 700;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      font-size: 18px;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
      letter-spacing: -0.36px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 16px;
      font-weight: 400;
      line-height: 28px; /* 175% */
      letter-spacing: -0.32px;
  `)}
`;

const Content = styled.div<{ $gap?: number }>`
  margin-top: ${({ $gap }) => ($gap ? `${$gap}px` : '50px')};

  ${({ theme }) =>
    theme.media.tablet(`
      margin-top: 50px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      margin-top: 30px;
  `)}
`;
