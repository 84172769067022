import AboutPage from '@pages/AboutPage';
import ApproachPage from '@pages/ApproachPage';
import CarbonBudgetPage from '@pages/CarbonBudgetPage';
import DashboardPage from '@pages/DashboardPage';
import HomePage from '@pages/HomePage';
import NetworkPage from '@pages/NetworkPage';
import TeamPage from '@pages/TeamPage';

export const routes = [
  { path: '/', element: <HomePage /> },
  { path: '/map', element: <CarbonBudgetPage /> },
  { path: '/dashboard', element: <DashboardPage /> },
  { path: '/about', element: <AboutPage /> },
  { path: '/approach', element: <ApproachPage /> },
  { path: '/network', element: <NetworkPage /> },
  { path: '/team', element: <TeamPage /> },
];
