import { useQuery } from '@tanstack/react-query';
import { getCarbonRankingData } from 'api/dashboardApi';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';

type TProps = {
  location: {
    ctp_cd: number;
    sig_cd?: number;
  };
  dataType: TCarbonSourceDataType[];
};

export const useTopRankCarbonQuery = (condition: TProps, enabled: boolean) => {
  const { location, dataType } = condition;

  return useQuery({
    queryKey: ['dashboard', 'rank', location, dataType],
    queryFn: () =>
      getCarbonRankingData({
        ctp_cd: location.ctp_cd,
        type: location.sig_cd ? 'sig' : 'ctp',
        data: location.sig_cd ? location.sig_cd : location.ctp_cd,
        dataType,
      }),
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
  });
};
