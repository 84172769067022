import hexToRGBA from '@utils/hexToRGBA';
import { useState } from 'react';
import styled from 'styled-components';

type Props = {
  min: number;
  max: number;
  defaultValue?: number;
  value?: number;
  step?: number;
  filledColor?: string;
  handleChange?: (value: number) => void;
};

const RangeSlider = ({ min, max, defaultValue, value, step = 1, filledColor, handleChange }: Props) => {
  const [currentValue, setCurrentValue] = useState(defaultValue || min);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = Number(e.target.value);
    handleChange && handleChange(selectedValue);
    if (value === undefined) {
      setCurrentValue(selectedValue);
    }
  };

  return (
    <Container>
      <StyledRangeInput
        type="range"
        min={min}
        max={max}
        value={value !== undefined ? value : currentValue}
        step={step}
        onChange={handleValueChange}
        $outlineColor={filledColor}
      />
      <StyledTrack />
      <FilledTrack $maxValue={max} $value={value !== undefined ? value : currentValue} $filledColor={filledColor} />
    </Container>
  );
};

export default RangeSlider;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
`;

const StyledRangeInput = styled.input<{ $outlineColor?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 3;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: ${({ theme }) => theme.newColors.white};
    width: 12px;
    height: 12px;
    border-radius: 6px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    outline: 2px solid ${({ theme, $outlineColor }) => hexToRGBA($outlineColor || theme.newColors.primary, 0.3)};
  }
`;

const StyledTrack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.newColors.dark1};
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1) inset;
`;

const FilledTrack = styled.div<{ $maxValue: number; $value: number; $filledColor?: string }>`
  position: absolute;
  top: 0px;
  left: 0;
  width: ${({ $maxValue, $value }) => `${100 * ($value / $maxValue)}%`};
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme, $filledColor }) => $filledColor || theme.newColors.grey1};
  z-index: 2;
`;
