import React, { useEffect, useState } from 'react';
import { Layer } from '@deck.gl/core/typed';
import { DeckGLOverlay } from '../common/layer/DeckGLOverlay';
import { TCarbonSourceDataType, TCarbonType, TGetMonthlyCarbonDataResponse, TPopupCarbonDataType } from 'interfaces/mapInterface';
import { TGetDistrictsDataResponse } from 'interfaces/districtInterface';
import { convertDistrictsData } from '@utils/convertDistrictData';
import { FeatureCollection, Position } from '@turf/helpers';
import CarbonGeojsonLayer from '@components/common/layer/CarbonGeojsonLayer';
import { getCarbonLegendType } from '@utils/carbonData';

type Props = {
  ctp_cd: number;
  dataType: TCarbonSourceDataType[];
  data: TGetMonthlyCarbonDataResponse | undefined;
  districtsData: TGetDistrictsDataResponse[] | undefined;
  selectedId: string | undefined;
  onClick: (data: { id: number; data: TPopupCarbonDataType; position: Position }) => void;
};

const MapLayer = ({ ctp_cd, dataType, data, districtsData, selectedId, onClick }: Props) => {
  const [layers, setLayers] = useState<Layer[]>([]);
  const [geoLayers, setGeoLayers] = useState<Layer[]>([]);
  const [features, setFeatures] = useState<{ data: FeatureCollection; min: number; max: number }>();

  useEffect(() => {
    if (!districtsData) return;
    if (!data) return;
    setFeatures(convertDistrictsData(districtsData, data));
  }, [districtsData, data]);

  useEffect(() => {
    if (!features) return;

    const id = `geojsonLayer_${ctp_cd}_${data?.yearMonth}_${dataType.join('_')}`;
    if (geoLayers.filter((layer) => layer.id === id).length > 0) {
      setGeoLayers(geoLayers.map((layer) => layer.clone({ visible: layer.id === id ? true : false })));
    } else {
      getGeojsonLayer(id, features.data, features.min, features.max, getCarbonLegendType(dataType), selectedId).then((res) =>
        setGeoLayers(geoLayers.map((layer) => layer.clone({ visible: false })).concat(res)),
      );
    }
  }, [features]);

  useEffect(() => {
    if (!geoLayers) return;
    setLayers(geoLayers);
  }, [geoLayers]);

  const getGeojsonLayer = async (
    id: string,
    data: FeatureCollection,
    min: number,
    max: number,
    carbonType: TCarbonType,
    selectedId?: string,
  ) => {
    return await CarbonGeojsonLayer({
      id,
      data,
      min,
      max,
      carbonType,
      selectedId,
      onClick,
    });
  };

  return <DeckGLOverlay layers={layers} interleaved={true} getCursor={({ isHovering }) => (isHovering ? 'pointer' : 'default')} />;
};

export default MapLayer;
