import useCloseWhenOutsideClick from '@hooks/useCloseWhenOutsideClick';
import styled from 'styled-components';
import { ReactComponent as ChevronIcon } from '@assets/icons/chevron_left_icon.svg';
import CarbonCategoryBody from '@components/common/selector/carbonCategorySelector/CarbonCategoryBody';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TProps = {
  selectorId: string;
  inputName: string;
  backgroundColor?: string;
  width?: string;
};

const CarbonCategorySelector = ({ selectorId, inputName, backgroundColor, width = '100%' }: TProps) => {
  const [isSelectorOpen, setIsSelectorOpen] = useCloseWhenOutsideClick(`#${selectorId}`);
  const {
    field: { value },
  } = useController({ name: inputName, rules: { required: true } });
  const { t } = useTranslation();

  const handleSelectorToggle = () => {
    setIsSelectorOpen((prev) => !prev);
  };

  return (
    <Container id={selectorId} $width={width}>
      <Header $backgroundColor={backgroundColor} $isSelected={value.length > 0} $isOpen={isSelectorOpen} onClick={handleSelectorToggle}>
        <span>
          {value.length === 0
            ? t('dashboard.carbonCategorySelector-select')
            : t('dashboard.carbonCategorySelector-selected', { length: value.length })}
        </span>
        <ChevronIcon />
      </Header>
      {isSelectorOpen && <CarbonCategoryBody inputName={inputName} backgroundColor={backgroundColor} />}
    </Container>
  );
};

export default CarbonCategorySelector;

const Container = styled.div<{ $width: string }>`
  position: relative;
  width: ${({ $width }) => $width};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.newColors.white};

  input.hide {
    display: none;
  }
`;

const Header = styled.div<{ $backgroundColor?: string; $isSelected: boolean; $isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.newColors.dark1};
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.newColors.white : theme.newColors.grey2)};
  border-radius: ${({ $isOpen }) => ($isOpen ? '5px 5px 0 0' : '5px')};
  cursor: pointer;

  span {
    width: 100%;
    max-width: calc(100% - 15px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    flex-shrink: 0;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'rotate(270deg)')};
  }
`;
