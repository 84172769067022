import axios from 'axios';

const BASE_URL = 'https://back.korea-carbon-project.org/api';

export const commonAxios = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: {
    indexes: null,
  },
});
